.product_detail_head {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 428px;
  height: 38px;
  z-index: 5;
  padding: 20px 20px 0px 20px;

  background-color: var(--secondry);
}

.product_detail_head > * {
  font-size: 14px;
}

.product_detail_head .menuIcon {
  margin: 0 0px;
  width: 36px;
  height: 36px;
}

.product_detail_head .header_left_arrow_link {
  width: 36px;
  height: 36px;
}

.product_detail_head .bell {
  margin: 0 0px;
  width: 36px;
  height: 36px;
}

.product_detail_head .headHeader {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.0625em;
  line-height: 1.5625;
  font-weight: 400;
  color: #fff;
}
