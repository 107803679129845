.wrapper {
  max-width: 767px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.wrapper .row {
  margin: 0;
}

h2,
h1,
h3,
h4,
h5,
h6,
p,
ul,
li,
a {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

h3 {
  font-size: 17px;
}

input,
textarea,
select,
button {
  font-family: "Poppins";
  outline: none;
  background: none;
}

a {
  text-decoration: none;
  color: #bf2c7d;
}

button {
  border: none;
  outline: none;
  background: none;
}

section {
  width: 100%;
  position: relative;
}

.marketplace-filter-wrpper {
  position: fixed;
  background: #fff;
  width: 100%;
  max-width: 400px;
  border-radius: 20px 20px 0px 0px;
  bottom: -100vw;
  max-height: 80%;
  z-index: 9;
  overflow-y: scroll;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.marketplace-filter-wrpper .filter-icon-and-title-div {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  background: #fff;
  z-index: 11;
}

.marketplace-filter-wrpper .filter-title {
  border-bottom: 1px solid #edf1f7;
}

.marketplace-filter-wrpper .filter-title h4 {
  font-size: 22px;
  color: #222b45;
  margin-bottom: 0;
}

.marketplace-filter-wrpper .filter-title a {
  font-size: 17px;
  color: #8f9bb3 !important;
}

.marketplace-filter-wrpper .filter-name h5 {
  font-size: 17px;
  color: #222b45;
}

.marketplace-filter-wrpper .filter-item-list {
  border-bottom: 1px solid #edf1f7;
}

.marketplace-filter-wrpper .filter-item {
  border-bottom: 1px solid #edf1f7;
}

.marketplace-filter-wrpper .checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 23px;
  height: 23px;
  background: #edf1f7;
  border-radius: 50%;
  position: relative;
}

.marketplace-filter-wrpper .checkbox:checked:after {
  content: url("../../../../assets/images/icons/checked.png");
  position: absolute;
  display: flex;
  justify-content: center;
  width: 23px !important;
  height: 23px !important;
}

.marketplace-filter-wrpper .filter-dropdown {
  display: none;
}

.marketplace-filter-wrpper .filter-drpdwn-active {
  display: block !important;
}

.marketplace-filter-wrpper .arrow-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.marketplace-filter-wrpper .arrow-icon-active {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.marketplace-filter-wrpper button.filter-btn {
  background: #01a2e6;
  color: #fff;
  padding: 10px 127px;
  border-radius: 10px;
  font-size: 17px;
}

.marketplace-filter-wrpper .filter-action {
  -webkit-box-shadow: #b3b3b342 0px -4px 10px;
  box-shadow: #b3b3b342 0px -4px 10px;
}

.marketplace-filter-wrapper-active {
  display: block;
  bottom: 0vw !important;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  box-shadow: #b3b3b342 0px -4px 10px;
}

.marketplace_filter_section_search .d-flex {
  display: flex !important;
}

.marketplace_filter_section_search .justify-content-center {
  justify-content: center !important;
}

.marketplace_filter_section_search .p-3 {
  padding: 1rem !important;
}

.marketplace_filter_section_search .px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.marketplace_filter_section_search .align-items-center {
  align-items: center !important;
}

.marketplace_filter_section_search .justify-content-between {
  justify-content: space-between !important;
}

.marketplace_filter_section_search .h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.marketplace_filter_section_search .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.marketplace_filter_section_search .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.marketplace_filter_section_search [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.hide {
  display: none;
}
