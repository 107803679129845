.page.military-gear {
  --bg: var(--absolute-white);
  font-size: 2.5vmin;
}

.page.military-gear > .content > decoratedtitle {
  margin-top: 4em;
}

.page.military-gear decoratedtitle {
  margin-bottom: 0.75em;
}

.page.military-gear imgdisplay {
  margin-inline-end: 4em;
}

/* .page.military-gear > .content > * {
  padding: 2em var(--margin-start);
} */
.page.military-gear
  .repository
  > .details-display
  .details
  > .flexible:first-child {
  align-items: flex-start;
}

.page.military-gear
  .repository
  > .details-display
  .details
  > .flexible:last-child {
  align-items: flex-end;
}

.page.military-gear .repository > .details-display .details icontext.phone {
  margin-top: 0;
  margin-bottom: 0;
}

.page.military-gear .repository > .details-display .details > .flexible > * {
  margin-inline-end: auto;
}

.page.military-gear .repository > .details-display .time {
  max-width: 15em;
}

.page.military-gear .repository > .details-display .shirt {
  max-height: 4em;
}

.page.military-gear .laundry {
  padding-inline-end: 6em;
  margin-bottom: 3em;
}

.page.military-gear .laundry decoratedtitle > .title {
  font-family: Assistant;
  font-weight: 700;
  font-size: 2.5vmax;
}

.page.military-gear .laundry img {
  margin-inline-end: 2em;
  max-height: 5em;
}

.page.military-gear .laundry .hangers {
  margin-inline-start: 4em;
  margin-bottom: 3em;
}

.page.military-gear .armoury > .flexible > .flexible > * {
  margin: 0;
  margin-inline-end: 2em;
}

.page.military-gear .armoury > .flexible > .flexible {
  margin-bottom: 4em;
}

.schedule > .flexible {
  /* margin: 0 !important; */
}

openinghours {
  white-space: nowrap !important;
}

.page.military-gear .armoury > .flexible > .flexible > :last-child {
  margin: 0;
  align-self: flex-start;
}

.page.military-gear .armoury .schedule > .flexible > openinghours > div {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.page.military-gear
  .armoury
  .schedule
  > .flexible
  > openinghours:first-child
  > div
  > :not(.emphasis) {
  margin-inline-start: 0.5em;
}

.page.military-gear .schedule openinghours {
  margin: 0 1em;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.page.military-gear .armoury .schedule .break {
  font-family: SecularOne;
  text-align: center;
  color: var(--focus-color);
  max-width: 0.9em;
  word-break: break-all;
}

.page.military-gear .armoury > .flexible > .flexible .gun {
  width: 100%;
  max-width: 16.25em;
}

.page.military-gear .laundry br {
  display: none;
}

.page.military-gear .repository .details-display .details .flexible .shirt {
  margin: 1em;
}

@media only screen and (max-width: 600px) {
  .page.military-gear {
    font-size: 6vw;
  }

  .page.military-gear decoratedtitle {
    margin-bottom: 0;
  }

  .page.military-gear icontext {
    margin-top: 1.5em;
    margin-bottom: 0;
    --max-icon-width: 2.5em;
  }

  .page.military-gear .repository icontext.phone,
  .page.military-gear .repository icontext.time {
    --icon-width: 2em;
  }

  .page.military-gear .repository icontext.phone > .icon + a {
    margin-inline-start: 0;
  }

  .page.military-gear .repository icontext.phone > :not(:first-child) {
    /* margin-inline-start: 1.15em; */
  }

  .page.military-gear imgdisplay {
    margin-inline-start: -1em;
    width: calc(100% + 2em);
  }

  .page.military-gear .repository {
    font-size: 0.8em;
  }

  .page.military-gear .repository .details-display {
    flex-direction: column;
  }

  .main > .scrollable > .page.military-gear .repository .details {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .page.military-gear .repository imgdisplay {
    order: -1;
    margin-inline-start: -1em;
    width: calc(100% + 2em);
    margin-bottom: 3em;
    font-size: 6vw;
  }

  .page.military-gear .repository icontext {
    --icon-width: 8vw;
  }

  .page.military-gear .repository .details > .flexible {
    display: contents;
  }

  .page.military-gear .repository .details .openingAndAddress {
    margin-bottom: 20px;
  }

  .page.military-gear .repository .location {
    order: -2;
  }

  .page.military-gear
    .repository
    > .details-display
    .details
    > .flexible
    > .time {
    order: -1;
    margin-inline-end: 0;
    min-width: unset;
    max-width: 48vw;
    margin-bottom: 1em;
  }

  .page.military-gear .repository .details-display .details .flexible .shirt {
    order: -1;
    margin-inline-end: 0;
    width: 22.75vw;
    margin-inline-start: 1em;
    margin-top: 0.75em;
    max-height: unset;
    margin-right: 0;
  }

  .page.military-gear
    .repository
    .details-display
    .details
    .flexible
    .shirtDiv {
    padding: 10px 0 0;
  }

  .page.military-gear .laundry decoratedtitle > .title {
    font-size: 7.5vw;
  }

  .page.military-gear .laundry {
    font-size: 0.8em;
    padding-inline-end: 2em;
    padding-top: 0.5em;
    line-height: 1.5em;
  }

  .page.military-gear .laundry > .flexible {
    flex-wrap: wrap;
  }

  .page.military-gear .laundry > .flexible > * {
    width: 100%;
  }

  .page.military-gear .laundry > .flexible > img {
    width: 16vw;
    margin-inline-end: 0;
  }

  .page.military-gear .laundry > .flexible > img + .flexible {
    display: contents;
  }

  .page.military-gear .laundry > .flexible > img + .flexible > .flexible {
    order: -1;
    width: 50vw;
    line-height: 1.5em;
    margin-inline-end: auto;
  }

  .page.military-gear .laundry br {
    display: inline;
  }

  .page.military-gear .laundry > .flexible > img + .flexible > .flex-end {
    margin: 1em 0;
  }

  .page.military-gear .laundry .hangers + div {
    width: 50vw;
  }

  .page.military-gear .laundry .hangers {
    order: 1;
    margin: 0;
    margin-inline-start: auto;
  }

  .page.military-gear .armoury {
    font-size: 0.8em;
  }

  .page.military-gear .armoury > .flexible > .flexible {
    display: contents;
  }

  .page.military-gear .armoury > .flexible > .flexible > icontext:first-child {
    order: -2;
  }

  .page.military-gear .armoury > .flexible > .flexible.schedule > :first-child {
    order: -2;
    margin-bottom: 2em;
  }

  .page.military-gear .armoury > .flexible > .flexible > * {
    margin-inline-end: 0;
    margin-bottom: 1em;
  }

  .page.military-gear .armoury > .flexible > .flexible > :last-child {
    margin-bottom: 1em;
  }

  .page.military-gear .armoury > .flexible > .flexible > :nth-child(2) {
    order: -1;
  }

  .page.military-gear .armoury > .flexible > .flexible > .phone {
    margin-top: 1em;
    margin-bottom: 3em;
  }

  .page.military-gear .schedule openinghours {
    margin: 0 0.85em;
  }

  .page.military-gear .schedule openinghours:last-child {
    margin-inline-end: 0;
  }

  .page.military-gear .armoury > .flexible > .flexible.schedule > :last-child {
    width: 100%;
    justify-content: center;
  }
}

.main > .scrollable > .military-gear.page .flexible.col {
  flex: unset;
}

.military-gear #dayhours {
  display: flex;
  justify-content: space-between;
}

.military-gear #white {
  color: white;
}

.military-gear #dayhours div:last-child {
  margin-right: 5px;
}

.main .scrollable .page .content .repository .flexible {
  display: unset;
}

.main .scrollable .page .content .repository .flexible .expand {
  padding: 1em;
}
