#serviceListHeader a img {
  max-width: unset;
}

.ServiceListHeaderImg {
  width: 100px;
  height: 21px;
}

.service_heading h3 {
  color: #222b45;
}

.service_heading .viewAll {
  color: #222b45;
  /* font-size: 20px; */
}

.service_heading .viewAll svg {
  transform: rotate(180deg);
  height: 100%;
}

@media only screen and (max-width: 770px) {
  .service_heading .viewAll svg {
    height: 17px;
  }
}
