.content .navigator {
  position: fixed;
  user-select: none;
  z-index: 2;
  width: 5%;
  /*transform: translate(0, -36%);*/
  bottom: 15px;
  margin: 0 1.5%;
  right: 1.5%;
}

.content .navigator a {
  position: relative;
  cursor: pointer;
  margin: 2vmax 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 4.632vmax;
  width: 4.632vmax;
  padding: 1vmax;
  border-radius: 50%;
  border: 3px solid var(--absolute-black);
  background-color: var(--bg);
  box-shadow: 0 0 15px 0.5px hsl(0deg 0% 100%);
  margin: 0 0 50px !important;
}

.content .navigator a>img {
  width: 100%;
  height: 100%;
}

.content .navigator .active {
  background-color: var(--focus-color);
  border-color: var(--focus-color);
}

.content .navigator .active .notActiveIcon {
  display: none;
}

.content .navigator .active .activeIcon {
  display: block;
}

.content .navigator .activeIcon {
  display: none;
}

.content .navigator .notActiveIcon {
  display: block;
}

.content .navigator a:hover>.notActiveIcon {
  display: none;
}

.content .navigator a:hover>.activeIcon {
  display: block;
}

.content .navigator a:hover {
  background-color: var(--absolute-black);
  border-color: var(--absolute-black);
}

.content .navigator .hoverText {
  display: none;
}

.content .navigator a:hover>.hoverText {
  display: block;
  position: absolute;
  top: 100%;
  margin: 0 0 0.25em;
  white-space: nowrap;
  font-size: 2.5vmin;
  color: var(--absolute-black);
}

.content .navigator .mainChat {
  width: 4.632vmax;
}

.content .navigator .mainChat:hover {
  color: var(--focus-color);
}

.content .textArea .customizer {
  padding-top: 0 !important;
}

/*@media only screen and (max-width: 1000px) {
  .content .navigator {
    margin: 0 -1%;
    right: 1%;
  }
}*/

@media only screen and (max-width: 600px) {
  .content .navigator {
    font-family: SecularOne;
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: var(--absolute-white);
    box-shadow: 0 25px 35px -10px #e6e6e6;
    position: absolute;
    -webkit-user-select: none;
    user-select: none;
    z-index: 2;
    padding: 0;
    bottom: unset;
    overflow-x: scroll;
  }

  .content .navigator a {
    background: none;
    color: black;
    border-color: var(--focus-color);
    margin: 0;
    font-size: 5vw;
    padding: 0.5em 0.5em;
    background: none;
    border: none;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    width: 100%;
    height: unset;
    box-shadow: none;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: unset;
  }

  .content .navigator a>img {
    width: 20px;
    height: 20px;
  }

  .content .navigator .active>.notActiveIcon {
    display: block;
  }

  .content .navigator a:hover>.notActiveIcon {
    display: block !important;
  }

  .content .navigator a>.activeIcon {
    display: none !important;
  }

  .content .navigator .hoverText {
    display: block;
    margin-top: 0;
    white-space: nowrap;
    font-size: 5vw;
  }

  .content .navigator a:hover {
    color: var(--focus-color) !important;
    background-color: unset !important;
    border-color: unset !important;
    font-size: 5vw;
  }

  .content .navigator .active {
    color: var(--focus-color) !important;
    background-color: unset !important;
    border-color: unset !important;
    font-size: 5vw;
  }

  .content .navigator a:hover>.hoverText {
    margin-top: 0px;
    white-space: nowrap;
    font-size: 5vw;
    color: unset !important;
    top: unset !important;
    font-size: unset !important;
  }

  .content .textArea {
    margin: unset !important;
  }

  .content .navigator a:hover>.hoverText {
    position: unset !important;
    font-size: 5vw;
  }

  .content .navigator .mainChat {
    width: 3.632vmax;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1em;
  }

  .sticky {
    position: fixed !important;
    top: 65px;
    width: 100%;
  }

  .sticky+.content .navigator a {
    padding-top: 102px;
  }

  .content .navigator {
    margin: 0;
    right: 0;
  }
}

.content .textArea {
  padding-top: 2em;
}