* {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.main,
.place-holder {
  background-image: linear-gradient(45deg,
      purple 25%,
      transparent 0,
      transparent 75%,
      purple 0,
      purple),
    linear-gradient(45deg, purple 25%, #fff 0, #fff 75%, purple 0, purple);
  background-position: 0 0, 30px 30px;
  background-size: 60px 60px;
}

.main {
  position: relative;
  font-family: "Assistant";
  font-weight: 500;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main {
  height: 100vh;
}

.main,
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--absolute-black);
}

.webPage {
  padding-top: 65px;
}

.scrollable {
  overflow: auto;
  flex: 1;
  scroll-behavior: smooth;
  background-color: white;
}

.header {
  padding-top: 6vmin;
  padding-right: 20vmin !important;
  padding-bottom: 5.5vmin;
  background-color: var(--focus-color);
  color: var(--absolute-white);
  font-size: 2vmax;
}

.header,
.page.dining>.options>* {
  padding: var(--margin-start);
  /* padding-right: 11%; */
}

.header .title {
  font-size: 4vmax;
}

.main .page .header .subtitle {
  font-family: inherit;
}

.main .page .subtitle {
  font-family: "Secular One";
  font-weight: 400;
}

dropdown {
  position: relative;
  display: block;
  font-size: 1.25vmax;
  -webkit-tap-highlight-color: transparent;
  height: calc(1em + 2vh + 5px);
  margin-top: 0.4em;
  font-weight: 400;
  user-select: none;
  z-index: 0;
  --transition-time: 0.35s;
  transition: 0s z-index var(--transition-time);
}

dropdown>.content {
  transition: max-height var(--transition-time) ease-in-out;
  max-height: calc(1em + 2vh + 4.25px);
  overflow: hidden;
  border-radius: var(--border-radius);
  border: 3px solid var(--white-4);
  background-color: var(--bg);
}

dropdown>.content> :first-child {
  font-weight: 700;
}

dropdown>.content>* {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(1em + 2vh);
  padding: 0 1vh;
}

decoratedtitle {
  display: block;
  line-height: 1.2;
  width: 100%;
  text-align: right;
}

.main .page .retractable {
  cursor: pointer;
  display: flex;
  align-content: center;
}

dropdown>.content .retractable {
  position: absolute;
  left: 1em;
}

dropdown .retractable {
  width: 1em;
}

.main .page .retractable:after,
.main .page .retractable:before {
  content: "";
  display: inline-block;
  width: 15%;
  padding-top: 75%;
  background-color: var(--absolute-black);
  border-radius: 15px;
  transition: transform 0.5s ease-in-out;
}

.main .page .retractable:before {
  margin-inline-start: 20%;
  transform: rotate(45deg);
}

.main .page .retractable:after {
  margin-inline-start: 30%;
  transform: rotate(-45deg);
}

.main .page .retractable.opened:after {
  transform: rotate(-135deg);
}

.main .page .retractable.opened:before {
  transform: rotate(135deg);
}

.date {
  color: var(--focus-color);
  font-size: 0.8em;
}

.main .page .corona-warn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
}

.main .page .corona-warn>.warn-sign {
  font-size: 2.5em;
  background-color: var(--absolute-white);
  padding: 0.1em 0.6em 0;
  margin-bottom: -0.9em;
  border-radius: 50%;
  position: relative;
  border: 0.1em solid var(--absolute-black);
  font-family: SecularOne;
}

.main .page .corona-warn>.warn-body {
  background-color: var(--absolute-black);
  color: var(--emphasis-color);
  text-align: center;
  padding: 2em 2em 1em;
  font-size: 1.1em;
  font-weight: 700;
  border-radius: 0.5em;
}

.main .page .flexible {
  display: flex;
}

.main .page .x3.expand {
  flex: 3;
}

.main .page .x2.expand {
  flex: 2;
}

.emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.emphasis.inline {
  display: inline;
}

icontext {
  --icon-width: 6vmin;
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

icontext.first-centered> :first-child {
  align-self: center;
}

icontext>.icon {
  width: var(--icon-width);
  margin-inline-end: calc(var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em);
  margin-inline-start: calc(var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2);
}

icontext> :first-child {
  align-self: flex-start;
}

icontext>.icon~* {
  margin: 0;
}

icontext>.icon~.link,
icontext>.icon~.link+* {
  margin-inline-start: 0.5em;
}

.main .page .as-content,
.main>.scrollable>.page .as-content * {
  display: contents;
}

openinghours {
  display: block;
  margin: 1em 0;
}

.main .page .as-content,
.main>.scrollable>.page .as-content * {
  display: contents;
}

openinghours>div:first-child {
  margin-inline-start: 0;
}

openinghours>div {
  margin: 0 auto;
}

.main .page .alt-color {
  --emphasis-color: var(--focus-color);
}

.main>.scrollable>.page .flexible {
  display: flex;
}

.main>.scrollable>.page .flexible.col {
  flex-direction: column;
}

.main>.scrollable>.page .x4.expand {
  flex: 4;
}

.main>.scrollable>.page .flex-end {
  margin-top: auto;
  margin-bottom: 0;
}

.main>.scrollable>.page .flex-end> :last-child,
.main>.scrollable>.page .flex-end> :last-child :last-child,
.main>.scrollable>.page .flex-start {
  margin-bottom: 0;
}

.main>.scrollable>.page .x5.expand {
  flex: 5;
}

.main>.scrollable>.page .expand {
  flex: 1;
}

.main>.scrollable>.page .no-start-margin,
.main>.scrollable>.page .no-start-margin *,
.main>.scrollable>.page .no-start-margin-inside :last-child {
  margin-inline-start: 0;
}

imgdisplay>.images>img {
  cursor: pointer;
  min-width: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
}

.content .textArea {
  margin-inline-start: calc(100% - 90%);
}

.main>.footer>.content {
  font-size: 2.25em;
  margin-top: auto;
  padding-top: 4em;
  background: var(--absolute-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main>.scrollable>.footer {
  height: 0;
}

@media only screen and (max-width: 600px) {
  .main>.footer>.content {
    padding-top: 1em !important;
  }

  .footer>.content .till.text {
    font-size: 5vw !important;
    margin-top: 2em !important;
  }
}

.customizer-content>iframe {
  width: 100%;
  height: 91%;
}