/* .page {
  max-width: 428px;
  width: 100%;
} */

.postPage_1 .postImgDiv {
  height: 280px;
}

.postPage_1 .postPage_doctor {
  padding: 10px 10px;
  text-align: right;
}

.postPage_1 .postImgText {
  position: relative;
  top: -20%;
  padding: 1px 21px 17px 9px;
}

.postPage_1 .postImgPhoto {
  width: 100%;
  height: 285px;
}

.postPage_1 .postImgH {
  font-size: 24px;
  line-height: 1.28;
  color: white;
  font-family: "Poppins";
  display: flex;
  flex-direction: row-reverse;
}

.postPage_1 .doctor_tips .tips_title {
  padding: 10px 10px 0;
  font-family: "Poppins";
  direction: rtl;
}

.postPage_1 .doctor_tips {
  padding: 0px 10px;
  margin-bottom: 100px;
}

.postPage_1 .doctor_tips .tip_number {
  color: #bf2c7d;
  margin-left: 5px;
  direction: rtl;
}

.postPage_1 .doctor_tips h6 {
  padding: 15px 10px;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 300;
  color: black;
  /* display: flex; */
  /* flex-direction: row-reverse; */
  direction: rtl !important;
  text-align: right !important;
}

.postPage_1 .doctor_tips .doctor_name {
  padding: 20px 10px;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
}

@media only screen and (min-width: 500px) {
  .postPage_1 {
    padding: 6vh 6vw 0;
  }

  .postPage_1 .doctor_tips h6 {
    padding: 0px 10px;
    font-size: 15px;
  }

  .postPage_1 .doctor_tips .tips_title {
    padding: 35px 10px 10px;
    font-size: 25px;
  }
}
