.trainingCard:hover {
  /* border: 1px solid black; */
  transform: scale(1.1);
  transition: 400ms;
}

.trainingCard .card-title {
  font-size: 18px;
  color: #414042;
}

.trainingCard .card-text {
  font-size: 12px;
  color: #414042;
}
