/*This is for Side bar buttons*/

.customizer.open {
  right: 0;
}

.border-left-blue-grey.border-left-lighten-4 {
  border-left: 1px solid #cfd8dc !important;
}

.customizer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: -400px;
  bottom: 0;
  width: 400px;
  height: 100vh;
  padding: 0;
  -webkit-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -moz-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -o-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.customizer a.customizer-close {
  color: #000;
}

.customizer .customizer-close {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  width: auto;
  padding: 7px;
}

.customizer a.customizer-toggle {
  display: block;
  color: #6967ce;
}

.customizer .customizer-toggle {
  line-height: 56px;
  position: absolute;
  top: 35%;
  left: -54px;
  width: 54px;
  cursor: pointer;
  text-align: center;
  border-radius: 8px 0 0 8px;
  color: #be2c7d;
  background-color: #be2c7d;
}

.customizer .customizer-toggle a {
  width: 100%;
  display: flex;
  margin: 15px;
}

.customizer .customizer-content {
  height: 100%;
  overflow: scroll;
}

.customizer .customizer-content .closeBtnHeader .closeBtnDiv {
  background-color: #01a2e6;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 1% 5% 3% 0%;
  cursor: pointer;
  position: relative;
}

.customizer .customizer-content .closeBtnHeader .closeBtnDiv svg {
  position: absolute;
  width: 25px;
  height: 25px;
}

.customizer .customizer-content .closeBtnHeader .closeBtnDiv svg > path {
  color: white;
}

/*This is for Side bar buttons*/

@media (max-width: 480px) {
  .customizer {
    width: 100%;
  }
}
