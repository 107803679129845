/* Create the Default Paragraphs */
.marketpages.rtl * {
  text-align: right !important;
}

.marketpages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

.marketpages p {
  margin-bottom: 20px;
}

/* Other Typo */
.marketpages strong {
  font-weight: bold;
}

.marketpages hr {
  border: 0 #000 solid;
  border-top-width: 1px;
  clear: both;
  height: 0;
}

.marketpages ol {
  list-style: decimal;
}

.marketpages ul {
  list-style: disc;
}

.marketpages .unlisted {
  list-style: none;
}

/* 3. Images
------------------------------------------------------------------------------*/

/* Remove border around linked images */
.marketpages img {
  border: 0;
  border-style: none;
  width: 100%;
  border-radius: 20px;
  height: 120px;
}


/* 4. Links
------------------------------------------------------------------------------*/

/* Default Link Types */
.marketpages a {
  color: var(--pink-color);
  text-decoration: none;
}

.marketpages a:hover {
  color: var(--secondar-color);
}

.marketpages a:active {
  color: #e8941a;
}

.marketpages a:focus {
  outline: none;
}

.marketpages a.current {
  color: #e8941a;
}

.marketpages input,
textarea {
  font-family: "Assistant";
}

/* 5. Forms
------------------------------------------------------------------------------*/

.marketpages input[type="submit"] {
  -webkit-appearance: none;
  border: none;
  -webkit-text-size-adjust: none;
}

.marketpages select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.marketpages textarea {
  overflow: auto;
}

.marketpages input:focus::placeholder {
  font-size: 0;
}

.marketpages ::-webkit-input-placeholder {
  color: #000;
}

.marketpages :-moz-placeholder {
  color: #000;
}

.marketpages ::-moz-placeholder {
  color: #000;
}

.marketpages :-ms-input-placeholder {
  color: #000;
}

.marketpages ::-ms-input-placeholder {
  color: #000;
}

.marketpages :placeholder-shown {
  color: #000;
}

/* Removes fieldset borders. even on Opea 7 */
.marketpages fieldset {
  border: 1px solid transparent;
}

/* 6. Tables
------------------------------------------------------------------------------*/

/* Table Fixes */
.marketpages table {
  border-spacing: 0;
  border-collapse: collapse;
}

.marketpages td {
  text-align: right;
  font-weight: normal;
}

/* 7. Framework ------------------------------------------------------------------------------*/
/* Common CSS */
/*transition effect*/
.marketpages input,
textarea,
button,
select {
  font-family: "Helvetica";
}

.marketpages button {
  border: none;
  outline: none;
  background: none;
}

/* Page Layout */
.marketpages .container {
  max-width: 767px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.marketpages .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.marketpages .col-1,
.marketpages .col-2,
.marketpages .col-3,
.marketpages .col-4,
.marketpages .col-5,
.marketpages .col-6,
.marketpages .col-7,
.marketpages .col-8,
.marketpages .col-9,
.marketpages .col-10,
.marketpages .col-11,
.marketpages .col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.marketpages .col-1 {
  max-width: 8.33333333%;
}

.marketpages .col-2 {
  max-width: 16.66666667%;
}

.marketpages .col-3 {
  max-width: 25%;
}

.marketpages .col-4 {
  max-width: 33.33333333%;
}

.marketpages .col-5 {
  max-width: 41.66666667%;
}

.marketpages .col-6 {
  max-width: 50%;
}

.marketpages .col-7 {
  max-width: 58.33333333%;
}

.marketpages .col-8 {
  max-width: 66.66666667%;
}

.marketpages .col-9 {
  max-width: 75%;
}

.marketpages .col-10 {
  max-width: 83.33333333%;
}

.marketpages .col-11 {
  max-width: 91.66666667%;
}

.marketpages .col-12 {
  max-width: 100%;
}

.marketpages .d-flex {
  display: flex;
}

.marketpages .flex-wrap {
  flex-wrap: wrap;
}

.marketpages .align-center {
  align-items: center;
}

.marketpages .align-start {
  align-items: flex-start;
}

.marketpages .align-end {
  align-items: flex-end;
}

.marketpages .justify-center {
  justify-content: center;
}

.marketpages .justify-start {
  justify-content: flex-start;
}

.marketpages .justify-end {
  justify-content: flex-end;
}

.marketpages .justify-between {
  justify-content: space-between;
}

/* Header Area */
.marketpages .header_area {
  padding: 25px 0;
}

.marketpages .header_area .btn {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px #00000012;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketpages .header_area .btn img {
  max-width: 17px;
}

/* Banner Section */
.marketpages .banner {
  width: 100%;
  height: 280px;
  background: url("../../../assets/images/marketPlace/Dentistry_Pharmacy_Building.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 60px;
}

.marketpages .banner img {
  position: absolute;
}

.marketpages .banner::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #0000004f;
}

.marketpages .banner .menu_btn {
  top: 15px;
  right: 50px;
  z-index: 1;
  width: 26px;
}

.marketpages .banner_content {
  position: relative;
  z-index: 1;
  padding: 0 0 15px 10px;
}

.marketpages .banner_content h1 {
  color: var(--White-color);
  font-weight: 600;
  font-size: 25px;
  font: medium;
  width: 100%;
  padding-right: 10px;
}

.marketpages .banner_content h6 {
  color: var(--White-color);
  font-size: 15px;
  font-weight: 400;
  font: medium;
  padding-right: 10px;
}

/* Button */
.marketpages .box_btn {
  padding: 15px 0;
  box-shadow: 0px -2px 5px 0px #00000042;
  background: var(--White-color);
  text-align: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  margin-top: 15px;
}

.marketpages .box_btn .go_btn {
  max-width: 65%;
  width: 100%;
  margin: 0 auto;
  color: var(--White-color);
  background: #bf2c7d;
  border-radius: 10px;
  display: inline-block;
  padding: 8px 0;
  font-size: 17px;
  font-weight: 700;
  height: 40px;
}

.marketpages.rtl .box_btn * {
  text-align: center !important;
}

.marketpages .service_sec {
  padding: 25px 0 15px;
}

.marketpages .service_sec .sec_heading_area {
  padding: 0 10px 0 10px;
}

.marketpages .service_sec .sec_heading_area h3 {
  color: black;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 0;
}

.marketpages .service_sec .sec_heading_area a img {
  margin-right: 10px;
  margin-left: 3px;
  transition: all 0.5s ease;
  max-width: 11px;
}

.marketpages .service_sec .service_slider {
  padding: 15px 0 0;
}

.marketpages .service_sec .service_slider .slide_box {
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 12px #00000017;
  box-shadow: 0 2px 12px #00000017;
  position: relative;
  overflow: hidden;
  margin: 0 7px 15px;
  z-index: 1;
}

.marketpages .service_sec .service_slider .slide_box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 86px;
  top: 0;
  left: 0;
  background: #01a2e6;
}

.marketpages .service_sec .service_slider .slide_box .box_img {
  width: 90px;
  height: 90px;
  padding: 6px;
  background: #ffffff;
  border-radius: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 3px 6px #00000029;
  box-shadow: 0 3px 6px #00000029;
  position: relative;
  z-index: 1;
}

.marketpages .service_sec .service_slider .slide_box .box_img img {
  border-radius: 100%;
  object-fit: cover;
}

.marketpages .service_sec .service_slider .slide_box h6 {
  color: #222b45;
  margin-top: 10px;
}

.marketpages .service_sec .service_slider .slick-arrow {
  display: none !important;
}

.marketpages .service_sec .doctor_heading a {
  font-size: 16px;
  color: black;
}

.marketpages .service_sec .doctor_heading a svg {
  transform: rotate(180deg);
  margin-right: 5px;
  transition: all 0.5s ease;
  max-width: 20px;
}

.marketpages .vandors_sec {
  padding: 25px 0 15px;
}

.marketpages .vandors_sec .sec_heading_area {
  padding: 0 10px 0 10px;
  justify-content: flex-end;
}

.marketpages .vandors_sec .sec_heading_area h3 {
  color: black;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 0;
}

.marketpages .vandors_sec .sec_heading_area a {
  font-size: 16px;
  color: black;
}

.marketpages .vandors_sec .sec_heading_area a img {
  margin-right: 10px;
  margin-left: 3px;
  transition: all 0.5s ease;
  max-width: 11px;
}

.marketpages .vandors_sec .vandors_slider {
  padding: 15px 0 0;
}

.marketpages .vandors_sec .vandors_slider .slide_box {
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 12px #00000017;
  box-shadow: 0 2px 12px #00000017;
  position: relative;
  overflow: hidden;
  margin: 0 7px 15px;
  z-index: 1;
}

.marketpages .vandors_sec .vandors_slider .slide_box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 86px;
  top: 0;
  left: 0;
  background: #01a2e6;
}

.marketpages .vandors_sec .vandors_slider .slide_box .box_img {
  width: 90px;
  height: 90px;
  padding: 6px;
  background: #ffffff;
  border-radius: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 3px 6px #00000029;
  box-shadow: 0 3px 6px #00000029;
  position: relative;
  z-index: 1;
}

.marketpages .vandors_sec .vandors_slider .slide_box .box_img img {
  border-radius: 100%;
  object-fit: cover;
}

.marketpages .vandors_sec .vandors_slider .slide_box h6 {
  color: #222b45;
  margin-top: 10px;
}

.marketpages .vandors_sec .vandors_slider .slick-arrow {
  display: none !important;
}

.marketpages .vandors_sec .doctor_heading a svg {
  transform: rotate(180deg);
  margin-right: 5px;
  transition: all 0.5s ease;
  max-width: 20px;
}

.marketpages .service_list_sec {
  padding: 10px 10px;
}

.marketpages .service_list_sec #tabs-nav li a {
  font-weight: 400;
  text-transform: uppercase;
  color: #a5a6a8;
  padding: 6px 10px;
  border-radius: 50px;
  margin-bottom: 10px;
  display: inline-block;
}

.marketpages .service_list_sec #tabs-nav li.active a {
  background: #01a2e6;
  color: #ffffff;
}

.marketpages .service_list_sec #tabs-content .list_box_area {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box {
  width: 100%;
  position: relative;
  padding-right: 8px;
  padding-left: 8px;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 13px;
  position: relative;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img span {
  text-align: center !important;
  color: black;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .discount_dt {
  position: absolute;
  left: 16px;
  bottom: 15px;
  font-size: 15px;
  color: #ffffff;
  background: #bf2c7d;
  width: 63px;
  height: 35px;
  border-radius: 20px;
  text-align: center;
  line-height: 35px;
  font-weight: 600;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt {
  position: absolute;
  height: 37px;
  line-height: 37px;
  font-size: 15px;
  border-radius: 20px;
  right: 5px;
  bottom: 15px;
  background: #ffffff;
  text-align: center;
  font-weight: 700;
  margin: auto;
}

.marketpages .service_list_sec .time_dt .off15 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 135px;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt p {
  margin: 0 10px;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt.dark {
  background-color: #01a2e6;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt.dark strong {
  color: #fff;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt strong {
  color: #01a2e6;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_detail {
  padding: 0 9px;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_detail span {
  text-align: left !important;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_detail .product_title {
  width: 80%;

  color: #a5a6a8;
  font-weight: normal;
  word-wrap: break-word;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_detail .product_title p {
  font-size: 13px;
  margin-bottom: 0px !important;
  color: #a5a6a8;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_detail .product_title h6 {
  font-size: 13px;
  color: #a5a6a8;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_detail span {
  color: #01a2e6;
  font-weight: 600;
  font-size: 16px;
  font-family: "poppins";
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .wish_list_icon {
  position: absolute;
  top: 12px;
  left: 25px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #0000005c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.marketpages .service_list_sec #tabs-content .list_box_area .list_box .wish_list_icon svg>path {
  color: white;
}

.marketpages .category_list_sec {
  padding: 0px 20px 20px 10px;
  margin-bottom: 35px;
}

.customizer .customizer-content .marketpages .category_list_sec {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.marketpages .category_list_sec .category_list {
  margin-top: 10px;
}

.marketpages .category_list_sec h3 {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.marketpages .category_list_sec .category_list .list_box {
  margin-bottom: 60px;
}

.marketpages .category_list_sec .category_list .list_box .box_img {
  position: relative;
  overflow: hidden;
}

.marketpages .category_list_sec .category_list .list_box .box_img h6 {
  position: absolute;
  right: 10%;
  bottom: 10%;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1px;
}

.marketpages .category_list .box_img:before {
  content: "";
  background: -webkit-linear-gradient(top,
      transparent,
      rgba(0, 0, 0, 0.9) 400%);
  position: absolute;
  width: 93%;
  height: 100%;
  border-radius: 10px;
}

@media only screen and (max-width: 427px) {
  .search_area .search_form {
    max-width: 180px;
  }

  footer ul li {
    padding-left: 12px;
  }

  #togglee_menu ul li {
    margin-bottom: 15px;
  }

  #togglee_menu ul li a {
    font-size: 15px;
  }

  .service_list_sec #tabs-content .list_box_area {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

.marketpages .list-unstyled {
  padding-right: 0;
  list-style: none;
}

.marketpages .search_form {
  display: flex !important;
  align-items: center;
  border: 1px solid #eaebec;
  border-radius: 12px;
  width: 320px;
  padding: 0 15px;
}

.category_list_sec h3 {
  font-size: 17px;
  font-weight: 500;
}

.service_list_sec {
  direction: rtl;
}

@media only screen and (min-width: 376px) and (max-width: 420px) {
  .marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt {
    font-size: 12px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  .marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt p {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 376px) {
  .marketpages .service_list_sec #tabs-nav li a {
    font-size: 14px !important;
  }

  .marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt {
    font-size: 12px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  .marketpages .service_list_sec #tabs-content .list_box_area .list_box .box_img .time_dt p {
    margin: 0 10px;
  }
}

.marketpages .pb-5 {
  padding-bottom: 1rem !important;
}

.marketpages .search_sec .search_area .search_form {
  max-width: 80%;
}

@media all and (max-width: 400px) {
  .marketpages .search_sec .search_area .search_form {
    max-width: 68%;
  }
}

/* This is For Search Sec  */

.search_sec {
  padding: 15px 21px;
}

.search_sec .search_area {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.search_sec .search_area .filter_icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  background: #01a2e6;
}

.search_sec .search_area .filter_icon svg>path {
  color: white;
}

.search_sec .search_area .search_form {
  position: relative;
  height: 50px;
  max-width: 75%;
  width: 100%;
}

.search_sec .search_area .search_form .btn_sub .SearchImg {
  width: 20px;
  height: 20px;
}

.search_sec .search_area .input_ser {
  height: 40px;
  font-size: 14px;
  color: #77838f;
  border-radius: 6px;
  padding: 0 10px 0 30px;
  width: 100%;
  border: 0;
}

.search_sec .search_area .location_icon svg {
  width: 30px;
  height: 30px;
}

.search_sec .search_area .location_icon svg>path {
  color: black;
}

/* This is For Search Sec  */

/* This is For The Big Button  */

.marketpages .bills_pages_box_btn .bills_pages_box_btn_area {
  margin-top: 5%;
  text-align: center !important;
}

.marketpages .bills_pages_box_btn .bills_pages_box_btn_area .bills_pages_box_go_btn {
  max-width: 97%;
  width: 100%;
  margin: 0 auto;
  color: var(--white-1);
  background: var(--gradient);
  border-radius: 10px;
  display: inline-block;
  padding: 10px 0;
  font-size: 17px;
  font-weight: 600;
  height: 48px;
  text-align: center !important;
}