.page.transport {
  --bg: var(--absolute-white);
  font-weight: 500;
}

.page.transport>.header,
.page.transport>.options>* {
  padding: 4vmin 6vmin 6vmin;
}

.page.transport>.header {
  background-color: var(--focus-color);
  color: var(--absolute-white);
  /* font-size: 1.5vmax */
}

.page.transport>.header>.title {
  font-size: 3.5vmax;
}

.page.transport>.header>img {
  height: 0.275vmax;
  margin-bottom: 1em;
}

.page.transport>.options {
  display: flex;
  flex: 1;
}

.page.transport>.options .dropdown {
  width: 91%;
}

.page.transport>.options>* {
  font-size: 2vmin;
  display: flex;
  flex-direction: column;
}

.page.transport>.options .title {
  font-size: 3vmax;
}

.page.transport>.options>div {
  align-items: baseline;
}

.page.transport .location {
  line-height: 1.5;
}

.page.transport .location,
.page.transport .schedule {
  display: flex;
  align-items: center;
  overflow-wrap: inherit;
  margin: 1em;
  /* max-width: 91%; */
  width: 40%;
  /* padding: 0 20%; */
}

.page.transport .location .text,
.page.transport .schedule .text {
  height: 35px;
}

.page.transport .location .icon {
  height: 100%;
  min-width: 25px;
}

.page.transport .location .icon,
.page.transport .schedule .icon {
  white-space: normal;
  justify-content: center;
  display: flex;
  margin: 0 0.5em;
  height: 2.5em;
  width: 2.5em;
  margin-inline-start: 0;
  margin-inline-end: 1.2em;
  max-width: 25px;
}

.page.transport select {
  transition: max-height var(--transition-time) ease-in-out;
  max-height: calc(1em + 2vh + 4.25px);
  overflow: hidden;
  border-radius: var(--border-radius);
  border: 3px solid var(--white-4);
  background-color: var(--bg);
}

.page.transport select> :first-child {
  font-weight: 700;
}

.page.transport .location>a:not([href]) .text {
  border: none;
  color: var(--absolute-black);
  cursor: default;
}

.page.transport .location .icon,
.page.transport .schedule>.icon>img {
  height: 100%;
}

.page.transport .emphasis {
  color: #e59303;
  display: inline;
}

.page.transport .public-transport>.text {
  margin-bottom: auto;
}

.page.transport .public-transport>.text>.line {
  margin: 0.75em 0;
}

.page.transport .public-transport>img.bus-road {
  margin: 0 2em;
}

.page.transport>.options>.shuttle-schedule>dropdown {
  width: 80%;
}

.page.transport>.options>.shuttle-schedule>.location .icon {
  margin-inline-start: 0.6vmin;
}

.page.transport>.options>.shuttle-app {
  align-items: center;
}

.page.transport .shuttle-app>.subtitle {
  /* width: 50%; */
  text-align: center;
  margin-bottom: 2em;
}

.page.transport .shuttle-app .link.google-play {
  margin-top: 2em;
}

.page.transport .shuttle-app .link.appstore {
  border-radius: 2em;
  margin: 2em 0;
  padding: 0 0.5em;
}

.page.transport imgdisplay {
  margin-top: 3.5em;
}

.page.transport .swiperDiv {
  margin-top: 3.5em;
}

.main .page.transport .category-separator {
  display: none;
}

.hoverswitch>.focused {
  position: relative;
  z-index: 1;
  box-shadow: -15px 0 50px -50px #000, 15px 0 50px -50px #000;
  transform: translateZ(1px);
}

.hoverswitch>div {
  flex: 1;
  background-color: var(--bg);
  transition: 0.5s linear;
  transition-property: background-color, box-shadow;
}

.icontext {
  --icon-width: 6vmin;
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.icontext .location {
  --icon-width: 5.25vmin;
}

.icontext> :first-child {
  align-self: flex-start;
}

.link .text {
  color: var(--focus-color);
  display: inline;
  border-bottom: 3px solid;
  font-weight: 700;
}

.icontext>.icon {
  width: var(--icon-width);
  margin-inline-end: calc(var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em);
  margin-inline-start: calc(var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2);
}

.icontext>.icon~* {
  margin: 0;
}

.openinghours {
  display: block;
  margin: 1em 0;
}

.openinghours>div:first-child {
  margin-inline-start: 0;
}

.openinghours>div>div {
  display: inline;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
}

.main .page .category-separator,
.main .page .retractablecategory>.separator {
  display: block;
  width: 100%;
  height: 0.2em;
  border-radius: 1em;
  background-color: var(--grey);
  opacity: 0.4;
  margin: 0.35em 0 0;
  transform: translateX(2px);
}

.button.store {
  width: 10em;
  padding: 0;
  border: none;
  margin: 0.5em;
}

.button.store>.icon {
  width: 100%;
  margin: 0;
  height: unset;
}

.button.store.google-play>.icon {
  width: 115%;
  margin: -0.8em 0;
}

.shuttle-app>.title {
  margin-bottom: 0.5rem;
}

imgdisplay {
  --transition-time: 0.5s;
  position: relative;
  width: 35vw;
  display: flex;
  user-select: none;
  margin-bottom: 3vmax;
  height: max-content;
  height: fit-content;
}

imgdisplay[display="many"] {
  width: 100%;
}

imgdisplay>.images {
  overflow: hidden;
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  width: 100%;
}

imgdisplay>.images>img,
imgdisplay>.overlay {
  transition: var(--transition-time) ease-in-out;
  transition-property: transform;
}

imgdisplay>.overlay {
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

imgdisplay>.images {
  overflow: hidden;
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  width: 100%;
}

imgdisplay[display="many"]>.images>img {
  min-width: 35vw;
  margin-inline-end: 2em;
}

imgdisplay>.overlay>.prev {
  transform-origin: calc(50% * (var(--dir-num) + 1));
  background-image: linear-gradient(var(--dir-to-angle),
      var(--absolute-black),
      var(--black-o75) 20%,
      transparent 75%);
}

imgdisplay>.overlay>.nav {
  cursor: pointer;
  background-size: 100% 100%;
  width: 5vw;
  --bg: var(--absolute-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

imgdisplay>.overlay>.next {
  transform-origin: calc(50% * (var(--dir-num) - 1) * -1);
  background-image: linear-gradient(var(--dir-angle),
      var(--absolute-black),
      var(--black-o75) 20%,
      transparent 75%);
}

imgdisplay>.overlay>* {
  pointer-events: all;
  transition: var(--transition-time) transform ease-in-out;
}

imgdisplay.zoom-in>.overlay>.indicators,
imgdisplay[display="many"]>.overlay>.indicators {
  --indicators-trans-origin: -3vh;
  margin-bottom: -4vh;
}

imgdisplay>.overlay>.indicators {
  --indicators-trans-origin: -2.5vh;
  position: absolute;
  bottom: 0;
  margin-bottom: -3vh;
  display: flex;
  left: 50%;
  transform-origin: center var(--indicators-trans-origin);
  transform: translate(-50%);
  transition-property: transform, transform-origin, margin-bottom;
}

imgdisplay>.overlay>.next:after,
imgdisplay>.overlay>.prev:before {
  transform: rotate(calc(var(--dir-num) * -45deg));
}

imgdisplay>.overlay>.nav:after,
imgdisplay>.overlay>.nav:before {
  --size: 2vmax;
  content: "";
  width: calc(var(--size) / 6);
  height: var(--size);
  background-color: var(--bg);
  margin: calc(var(--size) / -5) 0;
  border-radius: calc(var(--size) / 2);
}

imgdisplay>.overlay>.next:before,
imgdisplay>.overlay>.prev:after {
  transform: rotate(calc(var(--dir-num) * 45deg));
}

imgdisplay>.overlay>.indicators>div {
  cursor: pointer;
  width: 0.5vmax;
  height: 0.5vmax;
  border-radius: 50%;
  background-color: var(--grey);
}

imgdisplay[display="many"]>.overlay>.indicators>div~div:not(.active) {
  margin-inline-start: 3vw;
}

.shuttle-schedule .content.open {
  max-height: 40vh;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .page.transport {
    font-size: 6vw;
  }

  .main .page.transport .category-separator {
    display: block;
  }

  .page.transport>.header {
    font-size: 6vw;
    padding: 2em 1em;
  }

  .page.transport>.options decoratedtitle {
    font-size: 6vw;
  }

  .page.transport>.header .title {
    font-size: 12.5vw;
  }

  .page.transport>.options>* {
    font-size: 0.8em;
    padding: 2em 6vw;
  }

  .page.transport .location .icon {
    width: 2em;
  }

  .page.transport .schedule .icon {
    width: 2.25em;
  }

  .page.transport .location {
    margin: 2.5em 0 0;
  }

  .page.transport .schedule {
    margin: 1.25em 0;
  }

  .page.transport>.options .title {
    font-size: 9vw;
  }

  .page.transport .public-transport>img.bus-road {
    margin: 1.5em 1.5em 0;
  }

  .page.transport .shuttle-app>.subtitle {
    width: 60%;
  }

  .page.transport>.options>.shuttle-schedule>dropdown {
    width: unset;
  }

  hoverswitch {
    flex-direction: column;
  }

  dropdown {
    font-size: 6vw;
  }
}