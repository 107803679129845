.main.page.home {
  --bg: var(--absolute-white);
}

.page.home .title {
  font-family: "Secular One" !important;
  line-height: 0.9em;
  margin-bottom: 0.23em;
  font-size: 3vmax;
}

.page.home>.introduction {
  display: flex;
  flex: 1;
  background-color: var(--absolute-white);
}

.page.home>.introduction>div {
  padding: 10vh 3em 3em;
  display: flex;
  flex-direction: column;
}

.page.home .welcome-greet {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 11;
}

.page.home .introduction .title {
  font-size: 3vmax;
}

.page.home .welcome-greet .title,
.page.home .welcome-greet>.subtitle {
  font-family: "Secular One" !important;
  color: var(--focus-color);
  line-height: 0.9em;
  margin-bottom: 0.23em;
}

.page.home .welcome-greet .subtitle {
  font-size: 2.5vmax;
}

.page.home .welcome-greet>.text {
  font-family: "Assistant";
  margin-top: 2.5em;
  margin-bottom: 3.5em;
  text-align: center;
  line-height: 2.7em;
  font-size: 1.75vmin;
  font-weight: 500;
}

.page.home .training-bases {
  flex: 15;
  height: 100%;
  overflow: auto;
  padding-top: 1em;
}

.page.home .training-bases>.text {
  /* margin-bottom: 3em; */
  /* margin-inline-start: 1.5em; */
  font-weight: 500;
  font-size: 1vmax;
}

.page.home .training-bases decoratedtitle>.subtitle {
  margin-top: 0.7em;
  font-family: unset;
  font-weight: 500;
}

.page.home .training-bases>.text .title,
.page.home>.services .title {
  line-height: 0.9em;
}

.page.home .title-underline {
  margin-bottom: 0.5em;
  display: block;
}

.page.home .training-bases>.links {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.page.home .training-bases>.links>.bhd.link {
  width: 12%;
  margin: 0 6%;
}

.page.home>.introduction>.active {
  background-color: var(--white-1);
  box-shadow: inset -20px 0 100px -100px #000;
}

.page.home>.services {
  padding: 5vmin var(--margin-start) 5vmin var(--margin-start);
}

.page.home>.services>.links {
  display: flex;
  flex-wrap: wrap;
  margin: 10vmin 0;
  justify-content: space-between;
}

.page.home>.services>.links>* {
  font-size: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Secular One" !important;
}

.page.home>.services>.links>div:hover>.link {
  background-color: var(--absolute-black);
}

.page.home>.services>.links>div:hover>[href="transport"].link>img {
  content: url(../../../assets/images/icon-transport-highlight.7c8c50e8.svg);
}

.page.home>.services>.links>div:hover>[href="dining"].link>img {
  content: url(../../../assets/images/icon-dining-highlight.f4b71af8.svg);
}

.page.home>.services>.links>div:hover>[href="commercial-centre"].link>img {
  content: url(../../../assets/images/icon-commercial-centre-highlight.2cdb6dd3.svg);
}

.page.home>.services>.links>div:hover>[href="recreation-and-fitness"].link>img {
  content: url(../../../assets/images/icon-recreation-and-fitness-highlight.80dece60.svg);
}

.page.home>.services>.links>div:hover>[href="services"].link>img {
  content: url(../../../assets/images/icon-general-services-highlight.0d5868d5.svg);
}

.page.home>.services>.links>div:hover>[href="military-gear"].link>img {
  content: url(../../../assets/images/icon-military-gear-highlight.3e727f55.svg);
}

.page.home>.services>.links>div:hover>[href="healthcare"].link>img {
  content: url(../../../assets/images/icon-healthcare-highlight.043eb756.svg);
}

.page.home>.services>.links .link {
  transition: filter 0.5s ease-in-out;
  border-radius: 50%;
  background-color: var(--absolute-white);
  box-shadow: 0 0 10px -2px rgba(30, 36, 39, 0.21);
  height: 8vw;
  width: 8vw;
  padding: 1vw;
  border: 3px solid var(--absolute-black);
  margin-bottom: 0.75em;
}

.page.home>.links {
  margin-top: 5vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page.home>.links>a {
  margin: 0 3em;
}

.page.home>.links>.button {
  font-size: 1.75vw;
  min-width: 10vw;
  --primary-color: var(--absolute-black);
}

.links>a>div {
  /* display: flex !important;
  justify-content: center !important;
  align-items: center !important; */
  margin: 0 5% !important;
  padding: 0 5% !important;
}

.page.home>.links>.button>.text {
  margin: 0 auto;
}

.page.home>.links>.button>img {
  width: 1em;
}

.page.home .button.report>img {
  margin: 0 0 0 0.6em;
}

.page.home .button.report-corona>img {
  margin-inline-end: 0.9em;
}

.page.home .button.report-corona>div {
  margin-inline-start: 1em;
}

.page.home .button>div {
  margin: 0 auto 0 0.6em;
}

.page.home .button {
  padding: 0.2em 0.8em;
}

.page.home .button.army {
  background-color: var(--absolute-black);
  color: var(--absolute-white);
  padding-inline-start: 1em;
  padding-inline-end: 0.6em;
}

.page.home .button {
  font-family: "assistant" !important;
  border: none;
  width: 80% !important;
}

/* 
.page.home .button {
  display: flex;
  justify-content: center;
  padding: 0%;
} */

.page.home .button.report {
  background-color: #ffe85c;
}

.page.home .button .report-corona {
  /* background-color: #cef2c9; */
  /* margin: 0%; */
}

.page.home .button.army>.icon {
  width: 1.2em;
}

.page.home .button.army {
  width: 1.2em;
}

.page.home {
  --bg: var(--absolute-white);
}

.page {
  flex: 1;
  /*display: none;*/
  flex-direction: column;
  background: var(--bg);
  overflow: hidden;
}

.video-display {
  background-color: var(--absolute-black);
  /*min-height: 89vh;*/
  position: relative;
}

.video-display>.overlay.blur {
  background: hsla(0, 0%, 72.5%, 0.4);
}

.video-display>.overlay {
  /*height: 100%;*/
  width: 100%;
  display: flex;
  /* position: absolute; */
  z-index: 0;
  align-items: center;
}

.overlay.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /*height: 100%;*/
  z-index: 1;
  transition: var(--transition-time) background-color ease-in-out;
  background-color: transparent;
  color: #fff;
  font-size: 1.5vmax;
}

.video-display>.overlay {
  /*height: 100%;*/
  width: 100%;
  display: flex;
  /* position: absolute; */
  z-index: 0;
  align-items: center;
  background-color: white;
}

.video-display>.overlay>.text {
  position: absolute;
  font-family: "Secular One" !important;
  line-height: 1.3;
  margin: auto 1.65em 1.25em 0;
  bottom: 0;
  font-size: 4vmin;
  color: var(--absolute-white);
  height: unset;
  color: #414042;
  display: none;
}

.video-display>.overlay>.text>.title {
  font-size: 1.5em;
}

.page.home .title {
  font-family: "Secular One" !important;
  line-height: 0.9em;
  margin-bottom: 0.23em;
  font-size: 3vmax;
}

.title {
  font-family: "Secular One" !important;
  font-size: 3vmax;
}

.video-display>.overlay>.play {
  margin: 0 auto;
  cursor: pointer;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  text-align: center;
}

.video-display>.overlay>.play>img {
  width: 6vw;
}

.video-display>.overlay>.play>video {
  height: auto;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}

.page.home>.introduction {
  display: flex;
  flex: 1;
  background-color: var(--absolute-white);
}

hoverswitch {
  flex: 1;
  display: flex;
  height: 100%;
}

hoverswitch>div:not(.focused) {
  --bg: var(--hover-2-color);
}

hoverswitch>div {
  flex: 1;
  background-color: var(--bg);
  transition: 0.5s linear;
  transition-property: background-color, box-shadow;
}

.page.home>.introduction>div {
  padding: 10vh 3em 3em;
  display: flex;
  flex-direction: column;
}

.page.home .welcome-greet {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 11;
  padding-top: 1em;
}

.page.home .welcome-greet .title,
.page.home .welcome-greet>.subtitle {
  font-family: "Secular One" !important;
  color: var(--focus-color);
  line-height: 0.9em;
  margin-bottom: 0.23em;
}

.page.home .introduction .title {
  font-size: 3vmax;
}

.page.home .welcome-greet .subtitle {
  font-size: 2.5vmax;
  font-weight: 400;
}

.page.home .welcome-greet .title,
.page.home .welcome-greet>.subtitle {
  font-family: "Secular One" !important;
  color: var(--focus-color);
  line-height: 0.9em;
  margin-bottom: 0.23em;
}

.page.home .welcome-greet>.text {
  font-family: "Assistant";
  margin-top: 2.5em;
  margin-bottom: 3.5em;
  text-align: center;
  line-height: 2.7em;
  font-size: 1.75vmin;
  font-weight: 500;
}

.page.home .button {
  padding: 0.2em 0.8em;
}

.button.media>.icon {
  margin-inline-start: 0.4em;
  margin-top: 0.2em;
  margin-inline-end: 0;
  width: 0.65em;
  height: 0.65em;
}

.button>.icon {
  height: 1em;
  margin: 0.2em;
}

hoverswitch>.focused {
  position: relative;
  z-index: 1;
  box-shadow: -15px 0 50px -50px #000, 15px 0 50px -50px #000;
  transform: translateZ(1px);
}

.page.home .training-bases>.text {
  /* margin-bottom: 3em; */
  margin-inline-start: 5em;
  font-weight: 500;
  font-size: 1vmax;
}

decoratedtitle {
  display: block;
  line-height: 1.2;
}

.page.home .training-bases>.text .title,
.page.home>.services .title {
  line-height: 0.9em;
}

.page.home .title-underline {
  margin-bottom: 0.5em;
  display: block;
}

.title-underline {
  height: 0.3vmax;
  margin-bottom: 1em;
  user-select: none;
}

.page.home .training-bases>.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.page.home .training-bases>.links>.bhd.link {
  width: 12%;
  /*margin: 0 6%;*/
  margin: 2% 6%;
}

.bhd.link {
  text-decoration: none;
  margin: 0 6vmin;
  width: 9vmin;
  text-align: center;
  font-weight: 500;
  font-size: 1.5vmin;
  line-height: 1.25;
}

.bhd.link,
.link:visited,
.link :visited {
  color: var(--absolute-black);
}

.bhd.link>.imgWrapper>img {
  border-radius: 50%;
  width: 100px !important;
  height: 100px !important;
  object-fit: contain;
}

.bhd.link>.imgWrapper {
  border-radius: 50%;
  width: 100px !important;
  box-shadow: 0 0 15px -5px var(--highlight-color, var(--focus-color));
  height: 100px !important;
  margin: auto !important;
}

.bhd.link>.tag {
  font-family: "Secular One";
  margin-top: 0.5em !important;
  /* font-size: 1.0vmax; */
}

.page.home>.services {
  padding: 5vmin var(--margin-start) 5vmin var(--margin-start);
  background: white;
}

.decoratedtitle {
  display: block;
  line-height: 1.2;
}

.page.home .training-bases>.text .title,
.page.home>.services .title {
  line-height: 0.9em;
}

.page.home>.links {
  margin-top: 5vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page.home .button.report-corona {
  background-color: #cef2c9;
}

.page.home>.links>.button {
  /* font-size: 1.75vw; */
  /* min-width: 10vw; */
  /* --primary-color: var(--absolute-black); */
  max-width: 800px;

}

.page.home>.links>a {
  /* margin: 0 3em; */
}

.page.home .button>div {
  /* margin: 0 auto 0 0.6em; */
}

.page.home .button.report-corona>div {
  /* margin-inline-start: 1em; */
}

.page.home .button.report-corona>img {
  /* margin-inline-end: 0.9em; */
}

.button>.icon {
  height: 1em;
  margin: 0.2em;
}

.page.home .button.army {
  background-color: var(--absolute-black);
  color: var(--absolute-white);
  padding-inline-start: 1em;
  padding-inline-end: 0.6em;
}

.page.home .button.army>.icon {
  width: 1.2em;
}

.page.home .button.report {
  background-color: #ffe85c;
}

.page.home .button.report>img {
  margin: 0 0 0 0.6em;
}

@media only screen and (min-width: 600px) {

  .video-display>.overlay,
  .video-display>.overlay>.play {
    height: auto;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  .video-display {
    /* min-height: 89vh; */
  }
}

@media only screen and (max-width: 600px) {
  .button {
    font-size: 6vw;
  }

  .video-display {
    width: 100%;
    /* height: 205px; */
    /* max-width: 410px; */
  }

  .video-display>.overlay>.text {
    top: 0;
  }

  .video-display>.overlay>.play>video {
    height: unset;
    width: 100%;
  }

  .page.home .introduction {
    display: contents;
    font-size: 6vw;
  }

  .page.home .training-bases {
    order: 1;
    padding: 30px;
  }

  .page.home .introduction .welcome-greet .title {
    font-size: 11vw;
  }

  .page.home .introduction .title,
  .page.home .title {
    font-size: 9vw;
  }

  .page.home decoratedtitle {
    font-size: 6vw;
  }

  .page.home>.links {
    order: 1;
    flex-direction: column;
  }

  .page.home .welcome-greet .subtitle {
    font-size: 7.33vw;
  }

  .page.home>.introduction>div {
    padding: 3em 1em;
  }

  .page.home .welcome-greet>.text {
    text-align: start;
    text-align: justify;
    font-size: 0.8em;
    margin-top: 1em;
    margin-bottom: 1.5em;
    line-height: 2;
    margin-left: 1em;
    margin-right: 1em;
  }

  .page.home .welcome-greet .media.button {
    /* align-self: flex-start; */
  }

  .page.home br {
    display: none;
  }

  .page.home>.services>.links .link {
    height: 22vw;
    width: 22vw;
    padding: 3vw;
    border-width: 2px;
  }

  .page.home>.services>.links>div {
    font-size: 4vw;
    margin-bottom: 2em;
    font-weight: bold;
  }

  .page.home>.introduction>.training-bases {
    height: 80vh;
    padding-top: 0.5em;
  }

  .page.home .training-bases decoratedtitle>.subtitle {
    display: none;
  }

  .page.home .training-bases>.links {
    justify-content: space-between;
    gap: 10px;
  }

  .page.home .training-bases>.links:after {
    content: "";
    display: block;
    width: 25.25vw;
  }

  .page.home .training-bases>.links>.bhd.link .tag {
    font-size: 3.5vw;
    font-weight: bold;
  }

  .page.home .training-bases>.links>.bhd.link {
    width: 22vw;
    font-size: 3.25vw;
    margin: 0;
  }

  .page.home>.links>.button {
    margin-bottom: 2em;
    font-size: 7.5vw;
    min-width: 60vw;
  }

  .page.home>.links>.button:last-child {
    order: -1;
  }

  .page.home>.services {
    padding-top: 3em;
  }

  .page.home>.services>.links {
    margin-top: 3em;
  }

  .main>.footer>.content {
    padding-top: 0;
  }

  .footer>.content .till.icon {
    width: 24vw;
    margin-bottom: 0.25em;
  }

  .footer>.content .button {
    padding: 0.1em 1.2em;
    font-size: 7vw;
  }

  .page.home .introduction .welcome-greet {
    padding: 3em 1em !important;
  }

  .page.home .welcome-greet .media.button {
    align-self: flex-start;
  }

  .page.home .training-bases>.text {
    margin-inline-start: 0em !important;
  }
}

.home .services .links .transport .transportActive {
  display: none;
}

.home .services .links .transport:hover>a>.transportActive {
  display: block !important;
}

.home .services .links .transport:hover>a>.transportNotActive {
  display: none !important;
}

.home .services .links .dining .diningActive {
  display: none;
}

.home .services .links .dining:hover>a>.diningActive {
  display: block !important;
}

.home .services .links .dining:hover>a>.diningNotActive {
  display: none !important;
}

.home .services .links .commercialCentre .commercialCentreActive {
  display: none;
}

.home .services .links .commercialCentre:hover>a>.commercialCentreActive {
  display: block !important;
}

.home .services .links .commercialCentre:hover>a>.commercialCentreNotActive {
  display: none !important;
}

.home .services .links .recreationAndFitness .recreationAndFitnessActive {
  display: none;
}

.home .services .links .recreationAndFitness:hover>a>.recreationAndFitnessActive {
  display: block !important;
}

.home .services .links .recreationAndFitness:hover>a>.recreationAndFitnessNotActive {
  display: none !important;
}

.home .services .links .services .generalServicesActive {
  display: none;
}

.home .services .links .services:hover>a>.generalServicesActive {
  display: block !important;
}

.home .services .links .services:hover>a>.generalServicesNotActive {
  display: none !important;
}

.home .services .links .equipment .militaryGearActive {
  display: none;
}

.home .services .links .equipment:hover>a>.militaryGearActive {
  display: block !important;
}

.home .services .links .equipment:hover>a>.militaryGearNotActive {
  display: none !important;
}

.home .services .links .healthcare .healthcareActive {
  display: none;
}

.home .services .links .healthcare:hover>a>.healthcareActive {
  display: block !important;
}

.home .services .links .healthcare:hover>a>.healthcareNotActive {
  display: none !important;
}

.home .introduction .focused {
  position: relative;
  z-index: 1;
  box-shadow: -15px 0 50px -50px #000, 15px 0 50px -50px #000;
  transform: translateZ(1px);
}

.home .introduction .welcome-greet {
  height: 100%;
}

.home .introduction .training-bases {
  height: 100%;
}

.mabat {
  background-color: white !important;
  color: black !important;
  margin: 10%;
  /* border-radius: 50%; */
  box-shadow: 0 0 15px -5px var(--highlight-color, var(--focus-color));
  width: 250px !important;
  display: flex;
  justify-content: space-evenly;
  padding: 2% 5%;
  border-radius: 5%;
}

.mabat>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

.nop {
  padding: 0 !important;
}