.gynecologybanner {
  position: relative;
}

.gynecologybanner::before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.9) 300%
  );
  position: absolute;
  width: 100%;
  height: 100%;
}

.gynecologybanner .bannerBG {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.bannerText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 19px;
  padding: 0 20px 0 24px;
  height: 36px;
}

.bannerTextImg {
  height: 14px;
  margin-top: 10px;
}

.bannerTextH {
  font-size: 22px;
  line-height: 1.136;
  color: white;
}

@media only screen and (min-width: 500px) {
  .gynecologybanner::before {
    border-radius: 40px;
  }

  .gynecologybanner .bannerBG {
    height: 290px;
    /* width: 100%; */
    /* object-fit: cover; */
    border-radius: 40px;
  }

  .bannerText {
    bottom: 35px;
  }

  .bannerTextH {
    font-size: 40px;
  }
}
