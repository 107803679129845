.page.media {
  --bg: var(--absolute-white);
  font-size: 2.25em;
}

.page.media .title {
  font-size: 3.5vmax;
}

.page.media navigatable>.content>.navigator>a {
  margin: 1vmax 0;
}

.page.media navigatable>.content>.navigator>a>.hover-text {
  margin-top: 0.1em;
  font-size: 1.5vmax;
}

.page.media decoratedtitle {
  padding-top: 1.5em;
  margin-bottom: 1.5em;
}

.page.media .subtitle .title {
  font-size: 2.5vmax;
}

.page.media>navigatable>.content>[icon] {
  margin-bottom: 10vh;
  padding-inline-end: 0;
}

@media only screen and (max-width: 600px) {
  .page.media {
    font-size: 6vw;
  }

  .page.media decoratedtitle {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* .page.media navigatable > .content > .navigator + div {
    margin-top: 4em;
  } */
  .page.media navigatable>.content>.navigator {
    padding: 0 0.3em;
    box-sizing: border-box;
  }

  .page.media navigatable>.content>.navigator>a {
    margin: 0;
    padding: 0.5em;
  }

  .page.media navigatable>.content>.navigator>a>.hover-text {
    font-size: 0.8em;
  }

  .page.media .title {
    font-size: 12.5vw;
  }

  .page.media imgdisplay {
    margin-inline-start: -1em;
    width: calc(100% + 1em);
  }
}

@media only screen and (min-width: 600px) {
  .page.media navigatable>.content>.navigator>a {
    width: unset;
    /* margin-bottom: 2.5vh !important; */
    height: 3.632vmax;
    padding: 0.6vmax;
    aspect-ratio: 1 / 1;
  }

  .media .content .navigator a:hover>.hoverText {
    margin-top: 0.15em;
    margin: 0.25em;

    font-size: 2vmin;
  }

  .media .mainChat svg {
    aspect-ratio: 1 / 1;
    width: unset;
    height: 3.632vmax;
  }
}