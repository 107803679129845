.hospitalWebPages.rtl * {
  text-align: right !important;
}

.hospitalWebPages {
  padding: 6vh 3vw 0;
  background: white;
}

.division-container .hospitalWebBanner {
  position: relative;
}

.hospitalWebPages .service_heading {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 30px 2% 0px;
}

.hospitalWebPages .hospitalDepartments .hospitalDepartmentsArr {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-left: 7%;
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxImg {
  width: 30%;
  position: relative;
  margin: 6px;
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxImg
  img {
  width: 100%;
  height: 25vh;
  border-radius: 10px;
  object-fit: cover;
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxTitle {
  position: absolute;
  bottom: 5%;
  right: 4%;
  color: white;
  font-size: 29px;
  font-weight: 600;
}

.hospitalWebPages .webDoctorList .swiper-container {
  width: 85.6%;
  padding: 30px 0px 0px 0px;
  margin-left: 7%;
}

.hospitalWebPages .container .hospitalServicesList {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 0 5px;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-left: 3%;
}

.hospitalWebPages .container .hospitalServicesList .hospitalServicesListLink {
  width: 24%;
  padding-bottom: 35px;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  height: 100%;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  h4 {
  font-size: 20px;
  font-weight: 500;
  color: #222b45;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  p {
  font-size: 14px;
  margin-bottom: 0 !important;
  font-weight: 400;
  color: #8f9bb3;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  span {
  color: #8f9bb3;
  font-weight: 400;
  font-size: 14px;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxHeading {
  direction: rtl;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxDetails {
  display: flex;
  align-items: center;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxDetails
  svg {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxImg {
  width: 70px;
  margin-left: 3%;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxImg
  img {
  width: 100%;
  border-radius: 5px;
}

.hospitalWebPages .webBlogList .swiper-container {
  width: 85.6%;
  padding: 30px 0px 0px 0px;
  margin-left: 8%;
}

.hospitalWebPages .webBlogList .swiper-container .swiper-wrapper .swiper-slide {
  width: 345px !important;
  margin-right: 30px !important;
}

.hospitalWebPages .webBlogList .blogImg {
  width: 100%;
  border-radius: 10px;
  height: 23vh;
  object-fit: cover;
}

.hospitalWebPages .webBlogList .blogImgLink:before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.9) 300%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.hospitalWebPages .hospitalDepartmentsBoxImg:before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.9) 400%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.hospitalWebPages .container .webDoctorList .swiper {
  margin-top: 30px;
}

.hospitalWebPages .container .webBlogList .swiper {
  margin-top: 30px;
}
