.hospitalWebPages.rtl * {
  text-align: right !important;
}

.hospitalWebPages {
  padding: 6vh 3vw 0;
  background: white;
}

.hospitalWebPages .service_heading {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 30px 2% 0px;
}

.hospitalWebPages .hospitalDepartments .hospitalDepartmentsArr {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-left: 7%;
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxImg {
  width: 30%;
  position: relative;
  margin: 6px;
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxImg
  img {
  width: 100%;
  height: 25vh;
  border-radius: 10px;
  object-fit: cover;
}

.hospitalWebPages
  .hospitalDepartments
  .hospitalDepartmentsArr
  .hospitalDepartmentsBoxTitle {
  position: absolute;
  bottom: 5%;
  right: 4%;
  color: white;
  font-size: 29px;
  font-weight: 600;
}

.hospitalWebPages .webDoctorList .swiper-container {
  width: 85.6%;
  padding: 30px 0px 0px 0px;
  margin-left: 7%;
}

.hospitalWebPages .container .hospitalServicesList {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-left: 3%;
}

.hospitalWebPages .container .hospitalServicesList .hospitalServicesListLink {
  width: 24%;
  padding-bottom: 35px;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  h4 {
  font-size: 20px;
  font-weight: 500;
  color: #222b45;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  p {
  font-size: 14px;
  margin-bottom: 0 !important;
  font-weight: 400;
  color: #8f9bb3;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  span {
  color: #8f9bb3;
  font-weight: 400;
  font-size: 14px;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxHeading {
  direction: rtl;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxDetails {
  display: flex;
  align-items: center;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxCon
  .boxDetails
  svg {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxImg {
  /* width: 24%; */
  margin-left: 3%;
}

.hospitalWebPages
  .container
  .hospitalServicesList
  .hospitalServicesListLink
  .hospitalServicesListBox
  .boxImg
  img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.hospitalWebPages .webBlogList .swiper-container {
  width: 85.6%;
  padding: 30px 0px 0px 0px;
  margin-left: 8%;
}

.hospitalWebPages .webBlogList .swiper-container .swiper-wrapper .swiper-slide {
  width: 345px !important;
  margin-right: 30px !important;
}

.hospitalWebPages .webBlogList .blogImg {
  width: 100%;
  border-radius: 10px;
  height: 23vh;
  object-fit: cover;
}

.hospitalWebPages .webBlogList .blogImgLink:before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.9) 400%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.hospitalWebPages .hospitalDepartmentsBoxImg:before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.9) 400%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.hospitalWebPages .container .webDoctorList .swiper {
  margin-top: 30px;
}

.hospitalWebPages .container .webBlogList .swiper {
  margin-top: 30px;
}

h3 {
  margin-bottom: 0 !important;
}

.webDoctorList > .swiper > .swiper-wrapper > .swiper-slide {
  min-width: 100px !important;
}

.notebookBtn {
  font-size: 20px;
  position: fixed;
  width: 35px;
  top: 50%;
  right: 36px;
  display: none;
}

.hospitalWebPages .notebookBtn > img {
  color: #8f9bb3;
}

@media screen and (max-width: 770px) {
  .hospitalDepartmentsBoxImg {
    width: 29% !important;
    position: relative;
    margin: 2px;
  }

  .hospitalDepartmentsBoxTitle {
    font-size: 25px !important;
  }

  .swiper-slide {
    min-width: 60px !important;
    margin-right: 0px !important;
  }

  .hospitalServicesList,
  .hospitalDepartmentsArr,
  .webBlogList > .swiper > .swiper-wrapper {
    display: flex;
    flex-direction: column !important;
  }

  .hospitalServicesListLink,
  .hospitalDepartmentsBoxImg,
  .webBlogList > .swiper > .swiper-wrapper > .swiper-slide {
    width: 100% !important;
  }

  .webBlogList > .swiper > .swiper-wrapper > .swiper-slide {
    margin: 10px;
    height: 120%;
  }

  h6 {
    font-size: 15px !important;
  }

  .service_heading > * {
    font-size: 18px !important;
  }
}
