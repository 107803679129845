.tabsMenu {
  list-style: disc;
  padding-right: 0;
  list-style: none;
  display: flex;
  width: 100%;
  direction: rtl;
}

.tabsMenuLi {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  color: #a5a6a8;
  padding: 6px 10px;
  border-radius: 50px;
  margin-bottom: 10px;
  display: inline-block;
}

.marketPlace__tabs .Mui-selected {
  background: #01a2e6;
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
}
