.productdetail_pages.rtl * {
  text-align: right !important;
}

.productdetail_pages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

.productdetail_pages .productdetail_image_sec {
  background-color: var(--secondry);
}

.productdetail_pages .productdetail_image_div {
  padding: 35px 10px 0px 15px;
}

.productdetail_pages
  .productdetail_image_sec
  .productdetail_image_div
  .time_dt {
  position: absolute;
  width: 78.3px;
  height: 44.57px;
  line-height: 35px;
  border-radius: 17px;
  right: 10%;
  top: 6%;
  background: var(--primary);
}

.productdetail_pages
  .productdetail_image_sec
  .productdetail_image_div
  .time_dt
  .time_dt_p {
  color: white;
  padding: 5px 12px 0px 0px;
  font-size: 20px;
  font-weight: 700;
}

.productdetail_pages .productdetail_image_text_div {
  margin-top: 10px;
}

.productdetail_pages .productdetail_image_text_div .productdetail_p1 {
  font-size: 16px;
  font-weight: 400;
  text-align: center !important;
  padding: 0px 10px 0px 10px;
}

.productdetail_pages .productdetail_image_text_div .productdetail_p2 {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary);
  padding: 10px 10px 0px 10px;
  text-align: left !important;
}

.productdetail_pages .productdetail_about_div {
  padding: 10px 10px 0px 10px;
}

.productdetail_pages .productdetail_about_div h3 {
  font-size: 16px;
  font-weight: 500;
  color: #1e2022;
}

.productdetail_pages .productdetail_about_div p {
  font-size: 14px;
  font-weight: 400;
  color: #77838f;
  direction: rtl;
  margin: 0;
}

.productdetail_pages .productdetail_card {
  padding: 0px 10px 0px 10px;
}

.productdetail_pages .productdetail_card_div {
  text-align: left !important;
  margin-top: 20px;
  margin-left: 15px;
  box-shadow: 0px 0px 5px 0px #00000042;
  background: var(--White-color);
  border-radius: 10px;
  width: 87px;
  height: 65px;
  float: right;
}

.productdetail_pages .productdetail_card_div .productdetail_card_text {
  font-size: 32px;
  font-weight: 500;
  color: var(--primary);
  text-align: center !important;
  margin-bottom: 0;
}

.productdetail_pages .productdetail_card_div .productdetail_card_subtext {
  font-size: 9px;
  color: var(--secondry);
  font-weight: 400;
  text-align: center !important;
}

.productdetail_pages .productdetail_rating .productdetail_about_div_h1 {
  font-size: 22px;
  padding-top: 10px;
}

.productdetail_pages .productdetail_rating .productdetail_star_list {
  margin-top: 20px;
  margin-bottom: 50px;
  padding-right: 5px;
}

.productdetail_pages .productdetail_rating .fa-star {
  width: 13.5px;
  height: 12.8px;
  margin: 2px;
}

.productdetail_pages .productdetail_rating .checked {
  color: #bf2c7d;
}

.productdetail_pages .productdetail_rating .ratingimg {
  margin-right: 10px;
  /* height: 11.5px; */
  width: 64%;
}

.productdetail_pages .productdetail_rating {
  margin-top: 25%;
  padding: 0px 10px 0px 10px;
}

.productdetail_pages .productdetail_rating h5 {
  padding: 0px 10px 0px 0px;
}

.productdetail_pages
  .productdetail_rating
  .productdetail_star_list
  .productdetail_rating_p {
  font-size: 11px;
  font-weight: 500;
  color: #222b45;
  text-align: left !important;
  direction: rtl;
  padding: 10px 10px 0px 0px;
}

.productdetail_pages.rtl .box_btn * {
  text-align: center !important;
}

/* Button */
.productdetail_pages .box_btn {
  padding: 15px 0;
  box-shadow: 0px -2px 5px 0px #00000042;
  background: White;
  text-align: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  margin-top: 15px;
  z-index: 9;
}

.productdetail_pages .box_btn .buy_now_btn {
  width: 46%;
  margin: 0px auto;
  color: var(--White-color);
  background: var(--primary);
  border-radius: 8px;
  display: inline-block;
  padding: 10px 0;
  font-size: 17px;
  font-weight: 700;
  margin: 6px;
}

.productdetail_pages .box_btn .add_to_card_btn {
  background-color: var(--secondry);
}
