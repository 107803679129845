.page.recreationAndFitnessPage {
  --bg: var(--absolute-white);
}

.recreationAndFitnessPage .content {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
}

.recreationAndFitnessPage .content .textArea .libraryText {
  padding-top: 1em;
}

.recreationAndFitnessPage .content .textArea .libraryText .libraryDiv .swiper {
  width: 100%;
  height: auto;
  float: right;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .libraryDiv
  .swiper
  img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.recreationAndFitnessPage .content .textArea .libraryText .titleDiv {
  color: var(--focus-color);
  margin-bottom: 1em;
  margin-top: 4em;
  display: block;
  line-height: 1.2;
}

.recreationAndFitnessPage .content .textArea .libraryText .titleUnderline {
  height: 0.3vmax;
  margin-bottom: 1em;
  user-select: none;
}

.recreationAndFitnessPage .content .textArea {
  margin-inline-start: calc(100% - 90%);
}

.recreationAndFitnessPage .content .textArea .libraryText .details {
  padding-inline-start: 1em;
  display: flex;
  flex-direction: column;
}

.recreationAndFitnessPage .content .textArea .libraryText .details .flexible {
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  .detailsTimeImgDiv {
  margin-top: 0;
  align-items: flex-start;
  --icon-width: 6vmin;
  display: flex;
  margin-top: 1em;
  margin-bottom: 2em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  img {
  width: var(--icon-width);
  margin-inline-end: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em
  );
  margin-inline-start: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2
  );
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  .detailsTimeImgDiv
  .detailsTimeTextDiv {
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  .detailsTimeImgDiv
  .detailsTimeTextDiv
  div {
  display: flex;
  margin-right: 5px;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  .detailsTimeImgDiv
  .detailsTimeTextDiv
  div
  .emphasis {
  /*display: contents;*/
  color: var(--emphasis-color);
  font-weight: 700;
  margin-inline-start: 0.25em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  .detailsTimeImgDiv
  .detailsTimeTextDiv
  div
  .detailsTimeTextDivTime {
  display: inline;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .detailsPhoneImgDiv {
  margin-top: 1em;
  align-items: flex-start;
  --icon-width: 6vmin;
  display: flex;
  margin-bottom: 0em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  .detailsPhoneImgDiv
  .detailsPhoneImgDivLink {
  text-decoration: none;
  cursor: pointer;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexible
  .detailsPhoneImgDiv
  .detailsPhoneImgDivLink
  .detailsPhoneImgDivLinkNumber {
  margin-top: auto;
  margin-bottom: 0;
  color: var(--focus-color);
  display: inline;
  border-bottom: 3px solid;
  font-weight: 700;
}

.recreationAndFitnessPage .content .textArea .libraryText .details .flexEnd {
  margin-top: auto;
  margin-bottom: 0;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexEnd
  .titleDiv {
  display: block;
  line-height: 1.2;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexEnd
  .titleDiv
  .title {
  font-family: SecularOne;
  font-size: 3vmax;
  color: black;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexEnd
  .titleDiv
  .titleUnderline {
  height: 0.3vmax;
  margin-bottom: 1em;
  user-select: none;
}

.recreationAndFitnessPage
  .content
  .textArea
  .libraryText
  .details
  .flexEnd
  .flexEndTextDiv
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage .content .textArea .poolText {
  padding-top: 1em;
}

.recreationAndFitnessPage .content .textArea .poolText .titleDiv {
  color: var(--focus-color);
  margin-bottom: 1em;
  margin-top: 4em;
  display: block;
  line-height: 1.2;
}

.recreationAndFitnessPage .content .textArea .title {
  font-size: 3.75vmax;
  font-family: SecularOne;
}

.recreationAndFitnessPage .content .textArea .poolText .titleUnderline {
  height: 0.3vmax;
  margin-bottom: 1em;
  user-select: none;
}

.recreationAndFitnessPage .content .textArea .poolText .poolDiv .swiper {
  width: 100%;
  height: auto;
  float: right;
}

.recreationAndFitnessPage .content .textArea .poolText .poolDiv .swiper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.recreationAndFitnessPage .content .textArea .poolText .details {
  padding: 1em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.recreationAndFitnessPage .content .textArea .poolText .details .contentTopped {
  flex: 1;
  margin-top: 0;
  align-items: flex-start;
  --icon-width: 6vmin;
  display: flex;
  margin-bottom: 2em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .icon {
  height: 4vmax;
  width: var(--icon-width);
  margin-inline-end: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em
  );
  margin-inline-start: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2
  );
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours {
  flex: 1;
  margin-top: 0;
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  div {
  margin: 0 auto;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursOne {
  margin-right: 8px;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursTwo {
  margin-right: 8px;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursOne
  > div {
  margin-right: 5px;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursTwo
  > div {
  margin-right: 5px;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursThree
  > div {
  margin-right: 5px;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursOne {
  display: flex;
  margin-inline-start: 0;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursTwo {
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .contentTopped
  .openingHours
  .hoursThree {
  display: flex;
}

.recreationAndFitnessPage .content .textArea .poolText .details .titleDiv {
  margin-bottom: 0;
  display: block;
  line-height: 1.2;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .titleDiv
  .title {
  font-family: SecularOne;
  font-size: 3vmax;
  color: black;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .titleDiv
  .titleUnderline {
  height: 0.3vmax;
  margin-bottom: 1em;
  -webkit-user-select: none;
  user-select: none;
}

.recreationAndFitnessPage .content .textArea .poolText .details .flexible {
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin {
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
  --icon-width: 6vmin;
  display: flex;
  align-items: center;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  img {
  height: 4vmax;
  width: var(--icon-width);
  margin-inline-end: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em
  );
  margin-inline-start: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2
  );
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  .openingHours {
  flex: 1;
  margin-top: 0;
  margin: 0;
  display: block;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  .openingHours
  .hoursOne {
  margin-inline-start: 0;
  margin: 0 auto;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  .openingHours
  .hoursOne
  div {
  display: inline;
  align-items: center;
  justify-content: center;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  .openingHours
  .hoursOne
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv
  .openingHours
  .hoursOne
  div:last-child,
.hoursTwo div:last-child,
.hoursThree div:last-child {
  margin-right: 5px;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  .openingHours
  .hoursTwo {
  margin-inline-start: 0;
  margin: 0 auto;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  .openingHours
  .hoursTwo
  div {
  display: inline;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.recreationAndFitnessPage
  .content
  .textArea
  .poolText
  .details
  .flexible
  .noBottomMargin
  .openingHours
  .hoursTwo
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage .content .textArea .gymText {
  padding-top: 1em;
}

.recreationAndFitnessPage .content .textArea .gymText .titleDiv {
  color: var(--focus-color);
  margin-bottom: 1em;
  margin-top: 4em;
  display: block;
  line-height: 1.2;
}

.recreationAndFitnessPage .content .textArea .gymText .title {
  font-size: 3.75vmax;
  font-family: SecularOne;
}

.recreationAndFitnessPage .content .textArea .gymText .titleUnderline {
  height: 0.3vmax;
  margin-bottom: 1em;
  user-select: none;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .accordion-button {
  flex-direction: row-reverse;
  width: unset;
  margin-bottom: 1.5vmin;
  font-size: 2vmax;
  font-family: SecularOne;
  font-weight: 700;
  padding: unset;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .accordion-button::after {
  margin-left: 10px;
  width: 4vmax;
  height: 2.75vmax;
  background-size: 1.75rem;
  transition: transform 0.6s ease-in-out;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .accordion-item {
  border: unset;
  border-bottom: 3px solid rgba(0, 0, 0, 0.125);
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important;
  box-shadow: unset !important;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .accordion-button:focus {
  z-index: 3 !important;
  border-color: unset !important;
  outline: 0 !important;
  box-shadow: unset !important;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .accordion-button:not(.collapsed)::after {
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .accordion-body {
  padding: 2em 0em 4em 0em !important;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .imageDisplay
  .swiper {
  width: 100%;
  height: auto;
  float: right;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .imageDisplay
  .swiper
  img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details {
  padding: 1em;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  img {
  --icon-width: 5.25vmin;
  width: var(--icon-width);
  margin-inline-end: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em
  );
  margin-inline-start: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2
  );
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv {
  --icon-width: 6vmin;
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv
  .openingHours {
  display: block;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv
  .openingHours
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv
  .openingHours
  .hoursOne {
  display: flex;
  justify-content: space-between;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv
  .openingHours
  .hoursTwo {
  display: flex;
  justify-content: space-between;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv
  .openingHours
  .hoursThree {
  display: flex;
  justify-content: space-between;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .timeDiv
  img {
  align-self: flex-start;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .registerDiv {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .registerDiv
  img {
  --icon-width: 7.25vmin;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .registerDiv
  .openingHours {
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .details
  .registerDiv
  .openingHours
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .date {
  color: var(--focus-color);
  font-size: 0.8em;
  margin-top: 5em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility {
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule {
  flex: 0 1 32vmax;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule
  .gymFacilityTitleDiv {
  display: block;
  line-height: 1.2;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule
  .gymFacilityTitleDiv
  .title {
  font-family: SecularOne;
  font-size: 3vmax;
  color: black;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule
  .gymFacilityTitleDiv
  .titleUnderline {
  height: 0.3vmax;
  margin-bottom: 1em;
  user-select: none;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule
  .coronaWarn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule
  .coronaWarn
  .warnSign {
  font-size: 2.5em;
  background-color: var(--absolute-white);
  padding: 0.1em 0.6em 0;
  margin-bottom: -0.9em;
  border-radius: 50%;
  position: relative;
  border: 0.1em solid var(--absolute-black);
  font-family: SecularOne;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule
  .coronaWarn
  .warnBody {
  background-color: var(--absolute-black);
  color: var(--emphasis-color);
  text-align: center;
  padding: 2em 2em 1em;
  font-size: 1.1em;
  font-weight: 700;
  border-radius: 0.5em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .bhdSchedule
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .prepNote
  .titleDiv {
  display: block;
  line-height: 1.2;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .prepNote
  .titleDiv
  .title {
  font-family: SecularOne;
  font-size: 3vmax;
  color: black;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .prepNote
  .titleDiv
  .titleUnderline {
  height: 0.3vmax;
  margin-bottom: 1em;
  user-select: none;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .prepNote {
  margin-inline-start: auto;
  flex: 2 0 48vmax;
  max-width: 48.4vmax;
  --emphasis-color: var(--focus-color);
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .prepNote
  .notes {
  display: flex;
  flex-wrap: wrap;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .prepNote
  .notes
  .contentTopped {
  width: 50%;
  margin: 0 0 6.5% auto;
  align-items: flex-start;
  --icon-width: 6vmin;
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .gymContent
  .gymImageAndText
  .gymFacility
  .prepNote
  .notes
  .contentTopped
  img {
  width: var(--icon-width);
  margin-inline-end: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em
  );
  margin-inline-start: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2
  );
  align-self: flex-start;
  --icon-width: 7.25vmin;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .imageDisplay
  .swiper {
  width: 100%;
  height: auto;
  float: right;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .imageDisplay
  .swiper
  img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details {
  padding: 1em;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .flexible {
  display: flex;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .flexible
  .firstCentered {
  flex: 1;
  margin-top: 0;
  --icon-width: 6vmin;
  display: flex;
  align-items: center;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .flexible
  .firstCentered
  img {
  align-self: center;
  width: var(--icon-width);
  margin-inline-end: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em
  );
  margin-inline-start: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2
  );
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .flexible
  .firstCentered
  .openingHours
  .hours {
  margin-top: 0;
  margin-bottom: 0;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .flexible
  .firstCentered
  .openingHours
  .hours
  .emphasis {
  display: contents;
  color: var(--emphasis-color);
  font-weight: 700;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .flexible
  .firstCentered
  .openingHours
  .hours
  div {
  display: inline;
  align-items: center;
  justify-content: center;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .altColor {
  margin-top: 2em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .altColor
  .emphasis {
  /*display: contents !important;*/
  color: #01a2e6 !important;
  font-weight: 700 !important;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .altColor
  .flexEnd {
  --icon-width: 6vmin;
  display: flex;
  align-items: center;
  margin-top: 5em;
  margin-bottom: 2em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .studioRoomsContent
  .details
  .altColor
  .flexEnd
  img {
  --icon-width: 6vmin;
  width: var(--icon-width);
  margin-inline-end: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2 + 1em
  );
  margin-inline-start: calc(
    var(--max-icon-width, var(--icon-width)) / 2 - var(--icon-width) / 2
  );
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .hallAndSportsContent
  .imageDisplay
  .swiper {
  width: 100%;
  height: auto;
  float: right;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .hallAndSportsContent
  .imageDisplay
  .swiper
  img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .hallAndSportsContent
  .details {
  display: flex;
  flex-direction: column;
  padding: 1em;
  flex: 1;
  --emphasis-color: var(--focus-color);
  margin-bottom: 9em;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .hallAndSportsContent
  .details
  .flexStart {
  max-width: 18em;
  margin-bottom: 8%;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .fitnessGardensContent
  .imageDisplay
  .swiper {
  width: 100%;
  height: auto;
  float: right;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .fitnessGardensContent
  .imageDisplay
  .swiper
  img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.recreationAndFitnessPage
  .content
  .textArea
  .gymText
  .accordionDiv
  .fitnessGardensContent
  .details {
  display: flex;
  flex-direction: column;
  padding: 1em;
  flex: 1;
  --emphasis-color: var(--focus-color);
  margin-bottom: 15em;
  max-width: 25em;
}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  color: white;
}

.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .accordion-button {
    font-size: 6vw !important;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .accordion-button::after {
    height: 3.75vmax;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .gymContent
    .gymImageAndText
    .details
    .registerDiv {
    margin-top: 0px !important;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .gymContent
    .gymImageAndText
    .date {
    margin-top: 0px !important;
    font-weight: bold;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .gymContent
    .gymImageAndText
    .gymFacility
    .bhdSchedule
    .gymFacilityTitleDiv
    .title {
    font-size: 9vw;
    font-weight: bold;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .gymContent
    .gymImageAndText
    .gymFacility
    .bhdSchedule
    .emphasis {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .gymContent
    .gymImageAndText
    .gymFacility
    .bhdSchedule {
    margin-bottom: 4em;
    font-size: 4.5vw;
  }

  .recreationAndFitnessPage .content .textArea .title {
    font-weight: bold;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .gymContent
    .gymImageAndText
    .gymFacility
    .prepNote
    .notes
    .contentTopped {
    width: 80vw;
    margin-inline-end: auto;
    margin: 0 0 6.5% auto;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .gymContent
    .gymImageAndText
    .gymFacility
    .prepNote
    .notes
    .contentTopped
    img {
    width: 3.4em;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .studioRoomsContent
    .details
    .flexible
    .firstCentered
    img {
    margin-top: 1em;
    align-self: flex-start;
    width: 12vw;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .studioRoomsContent
    .details
    .flexible
    .firstCentered {
    margin-top: 2em;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .studioRoomsContent
    .details
    .altColor
    .flexEnd {
    margin-top: 2em;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .studioRoomsContent
    .details
    .altColor
    .flexEnd
    img {
    width: 9vw;
  }

  .details .flexStart .emphasis {
    /* display: flex; */
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .hallAndSportsContent
    .details {
    margin-bottom: 1em;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .accordion-body {
    padding: 2em 0em 0em 0em !important;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .gymText
    .accordionDiv
    .fitnessGardensContent
    .details {
    margin-bottom: 5em;
  }

  .recreationAndFitnessPage
    .content
    .textArea
    .libraryText
    .details
    .flexible
    img {
    width: 12vw;
  }
}

/* .recreationAndFitnessPage .emphasis {
  display: unset;
} */

.recreationAndFitnessPage .smallMargin {
  margin-right: 0.05em;
}
