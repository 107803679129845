.page.dining {
  background-color: var(--absolute-white);
  --bg: var(--absolute-white);
  font-size: 2vmin;
}

.page.dining .emphasis {
  color: var(--emphasis-color);
  display: inline;
}

.page.dining>.options>* {
  padding: 4vmin 6vmin 6vmin;
}

.page.dining>.header {
  background-color: var(--focus-color);
  color: var(--absolute-white);
  /* font-size: 1.5vmax */
}

.page.dining>.content> :first-child {
  margin-bottom: 8vmin;
  width: 100%
}

.page.dining>.content {
  padding: 6vmin;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.page.dining>.content>.description br {
  display: none;
}

.page.dining .menu,
.page.dining .opening-hours,
.page.dining .schedule {
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 25vh;
  margin-bottom: 12vh;
}

.page.dining .opening-hours {
  margin-bottom: 0;
}

.page.dining .opening-hours openinghours {
  margin-inline-start: 0.25em;
}

.page.dining .dropdowns {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 16.75vmax;
}

.page.dining .meal-details {
  margin: 0 auto;
  display: flex;
}

.page.dining .meal-details>.icons,
.page.dining .meal-details>.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 1em;
}

.page.dining .meal-details>.text {
  align-items: flex-start;
}

.page.dining .meal-details img.location {
  width: 2em;
}

.page.dining .meal-details img.time {
  width: 4em;
}

.page.dining .meal-preview>img {
  height: 4em;
  margin-bottom: 0.6em;
}

.page.dining .menu>.dropdowns {
  margin-inline-end: 1em;
}

.page.dining .meal-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 10.5em;
  margin: 0 auto;
}

.page.dining .menu {
  --emphasis-color: var(--focus-color);
}

.page.dining .opening-hours>div {
  margin: 3em auto 0;
}

.page.dining .opening-hours>div>.subtitle {
  margin-inline-start: 1em;
}

.page.dining .opening-hours>div>div {
  display: flex;
  align-items: center;
}

.page.dining .opening-hours>div img {
  width: 2em;
  margin: 1em;
}

.page.dining .food-deployments {
  width: 100%;
  margin-bottom: 4em;
}

/* decoratedtitle>.subtitle {
    font-size: 2em
} */

.page.dining .food-deployments .title {
  margin-top: 1em;
  font-size: 2.5vmax;
  font-family: "Secular One";
}

.page.dining .food-deployments .subtitle {
  margin-bottom: 0.5em;
  font-family: "Assistant";
}

.page.dining .food-deployments .time {
  --icon-width: 4.5vmin;
  --max-icon-width: 6vmin;
  margin-top: 0;
  margin-bottom: 3em;
}

.page.dining .disabled {
  display: none;
}

.main .page.dining .corona-warn {
  flex-direction: row;
  margin-top: 1em;
}

.main .page.dining .corona-warn>.warn-sign {
  margin-bottom: 0;
  margin-inline-end: -0.9em;
}

.main .page.dining .corona-warn>.warn-body {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-inline-start: 3em;
}

.description {
  font-size: 2vw !important;
}

@media only screen and (max-width: 600px) {
  img.icon {
    width: 50px;
    margin: 0 0 0 25px;
  }

  /* decoratedtitle>.subtitle {
        font-size: 1em;
    } */

  .page.dining {
    font-size: 6vw;
  }

  /* .page.dining>.header {
        font-size: 1em
    } */

  /* .page.dining>.header .subtitle {
        font-size: .8em
    } */

  .page.dining>.content {
    padding-top: 1.5em;
  }

  .page.dining>.content>.description {
    font-size: 0.825em;
    line-height: 1.6em;
    margin-bottom: 2.1em;
  }

  .page.dining>.content>.description br {
    display: inline;
  }

  .page.dining .menu,
  .page.dining .opening-hours,
  .page.dining .schedule {
    flex-direction: column;
  }

  .page.dining .dropdowns {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
  }

  .page.dining .dropdowns>dropdown>.content {
    width: 41vw;
    margin-inline-end: 0;
  }

  .page.dining .meal-details>.icons,
  .page.dining .meal-details>.text {
    display: contents;
    margin-top: 1em;
  }

  .page.dining .meal-details {
    align-items: center;
    justify-content: space-around;
    margin: 0.5em 0;
    font-size: 0.8em;
  }

  .page.dining .meal-details img.time {
    order: -1;
  }

  .page.dining .meal-details openinghours {
    order: -1;
    margin-inline-end: 1em;
    margin-inline-start: 1em;
  }

  .page.dining .meal-details openinghours+div {
    margin-inline-start: 1em;
  }

  .page.dining .meal-preview {
    flex-direction: row;
    margin-top: 1.5em;
    align-items: center;
    margin-inline-start: 0;
  }

  .page.dining .meal-preview:nth-child(2) {
    margin-top: 0.75em;
  }

  .page.dining .meal-preview>img {
    margin-bottom: 0;
    height: 3em;
  }

  .main .page .meal-preview>.text-center {
    margin-inline-start: 1.25em;
    text-align: start;
    margin-inline-end: 0.5em;
  }

  .page.dining .opening-hours>div {
    margin-inline-start: 0;
    margin-top: 0;
  }

  .page.dining .opening-hours {
    margin-bottom: 2em;
  }

  .page.dining .opening-hours>div:not(:first-child) {
    margin-top: 1em;
  }

  .page.dining .opening-hours>div>.subtitle {
    margin-inline-start: 0;
  }

  .page.dining .opening-hours>div img {
    margin-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .page.dining .food-deployments,
  .page.dining .food-deployments>decoratedtitle>.subtitle {
    font-size: 0.8em;
    font-weight: bold;
  }

  .page.dining .food-deployments .flexible {
    flex-direction: column;
  }

  .page.dining .food-deployments .subtitle .title {
    margin-top: 1em;
    font-size: 7vw;
  }

  .page.dining .food-deployments .subtitle img {
    display: block;
    margin-bottom: 1em;
  }

  .page.dining .food-deployments .time {
    --icon-width: 9vmin;
    --max-icon-width: 12vmin;
    margin-top: 0;
    margin-bottom: 3em;
  }

  .main .page.dining .corona-warn {
    flex-direction: column;
    font-size: 0.8em;
    margin-top: 1em;
  }

  .main .page.dining .corona-warn>.warn-sign {
    margin-bottom: -0.9em;
    margin-inline-end: 0;
  }

  .main .page.dining .corona-warn>.warn-body {
    padding-top: 2em;
    padding-bottom: 1em;
    padding-inline-start: 2em;
  }

  .description {
    font-size: 6vw !important;
  }
}

@media only screen and (max-width: 600px) {
  decoratedtitle .title {
    font-size: 9vw !important;
    font-weight: bold;
  }

  .page.dining .food-deployments .title {
    margin-top: 0px !important;
  }

  .description {
    font-size: 3vw !important;
  }

  .menu th {
    font-size: 20px;
  }

  .menu tr {
    font-size: 16px;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.day {
  vertical-align: middle;
  font-weight: bolder;
}



th>img {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}

th:nth-child(2),
.breakfast>td:nth-child(2) {
  background-color: rgb(250, 250, 250) !important;
}

.dining .accordion-button {
  width: unset;
  flex-direction: row-reverse;
}

.dining .accordion-button:not(.collapsed) {
  color: var(--emphasis-color);
}

.dining .accordion-button::after {
  margin-left: 10px;
}