.page.healthcare {
  --bg: var(--absolute-white);
  font-size: 1.5vmax;
}
.page.healthcare decoratedtitle {
  padding-top: 1.5em;
  margin-bottom: 0.75em;
}
.page.healthcare .title {
  font-size: 3.5vmax;
}
.page.healthcare > navigatable > .content .subtitle {
  font-family: Assitant;
  margin-top: 1.5em;
}
.page.healthcare .subtitle .title {
  font-size: 2.25vmax;
}
.page.healthcare .big-icon > .icon {
  width: 4.25em;
}
.page.healthcare > navigatable > .content > .textArea {
  margin-bottom: 2em;
}
.page.healthcare > navigatable > .content .button {
  max-width: 10vmax;
  height: 2em;
}
.page.healthcare > navigatable > .content .flexible > :first-child {
  margin-bottom: 1em;
}
.page.healthcare .perscription > img {
  margin-top: 0.5em;
}
.page.healthcare > navigatable > .content .medical-centre > icontext {
  margin-bottom: 1em;
}
.page.healthcare > navigatable > .content .clinic > .flexible > :first-child {
  flex: 4;
}
.page.healthcare > navigatable > .content .pharmacy openinghours {
  max-width: 9em;
}
.page.healthcare > navigatable > .content .pharmacy openinghours > div {
  text-align: end;
}
.page.healthcare > navigatable > .content .clinic > .flexible > * {
  flex: 3;
  margin-inline-end: 2em;
}
.page.healthcare > navigatable > .content .flexible:not(.col) > :nth-child(2n) {
  margin: 0 2em;
}
.page.healthcare > navigatable > .content icontext {
  margin: 0;
  flex: 1;
}
.page.healthcare > navigatable > .content .clinic openinghours {
  margin: 0 0 1em;
}
.page.healthcare > navigatable > .content b {
  display: contents;
}
.page.healthcare
  > navigatable
  > .content
  .medical-call-centre
  > .flexible
  > .icon {
  width: 2.25em;
  margin-inline-start: 0.5em;
  margin-bottom: 1.5em;
}
.page.healthcare > navigatable > .content .doctor-visits > .link {
  margin-top: 2.5em;
}
.page.healthcare > navigatable > .content .flexible.pharmacy > * {
  margin-inline-end: auto;
  max-width: 25vmax;
}
.page.healthcare .medical-call-centre .contact icontext {
  --icon-width: 2.5vmax;
  margin-bottom: 1.5vmax;
}
@media only screen and (max-width: 600px) {
  .page.healthcare {
    font-size: 6vw;
  }
  .page.healthcare .header decoratedtitle {
    margin-top: 0;
    margin-bottom: 0;
  }
  .page.healthcare .header .title {
    font-size: 12.5vw;
  }
  .page.healthcare .title {
    font-size: 9vw;
  }
  .page.healthcare navigatable > .content > .navigator > a {
    font-size: 0.8em;
    padding: 0.5em;
  }
  .page.healthcare navigatable > .content > .navigator {
    box-sizing: border-box;
  }
  .page.healthcare .clinic {
    font-size: 0.8em;
  }
  .page.healthcare .clinic > .flexible {
    flex-direction: column;
  }
  .page.healthcare > navigatable > .content .clinic .flexible icontext {
    margin: 1em 0;
  }
  .page.healthcare > navigatable > .content .clinic > .flexible > * {
    margin-inline-end: 0;
  }
  .page.healthcare > navigatable > .content .handshake {
    margin-top: 2.5em;
    margin-bottom: 1em;
  }
  .page.healthcare
    > navigatable
    > .content
    .flexible:not(.col)
    > :nth-child(2n):not(.icon) {
    margin: 1em 0;
  }
  .page.healthcare .handshake img {
    order: 1;
    margin-inline-start: 2em;
    margin-inline-end: 0;
  }
  .page.healthcare decoratedtitle {
    margin-bottom: 0;
  }
  .page.healthcare > navigatable > .content .subtitle {
    font-size: 6vw;
  }
  .page.healthcare > navigatable > .content .subtitle > .title {
    font-size: 6.5vw;
  }
  .page.healthcare > navigatable > .content .subtitle {
    margin-top: 2em;
  }
  .page.healthcare .medical-centre {
    font-size: 0.8em;
  }
  .page.healthcare .medical-centre > .flexible {
    flex-direction: column;
  }
  .page.healthcare > navigatable > .content .flexible.pharmacy > * {
    max-width: unset;
  }
  .page.healthcare > navigatable > .content .flexible.pharmacy > :last-child {
    margin-top: 0.5em;
  }
  .page.healthcare > navigatable > .content .pharmacy openinghours > div {
    text-align: start;
  }
  .page.healthcare .subtitle .title {
    font-size: 9vw;
  }
  .page.healthcare
    > navigatable
    > .content
    .medical-call-centre
    .subtitle
    .icon {
    margin-inline-start: 0.75em;
  }
  .page.healthcare .medical-call-centre {
    font-size: 0.8em;
  }
  .page.healthcare .medical-call-centre .flexible.pack {
    flex-direction: column;
  }
  .page.healthcare .medical-call-centre .contact icontext {
    --icon-width: 7.5vw;
    margin-bottom: 1em;
  }
  .page.healthcare .doctor-visits {
    font-size: 0.9em;
  }
  .page.healthcare .doctor-visits decoratedtitle {
    margin-bottom: 0;
  }
  .page.healthcare > navigatable > .content .doctor-visits > .link {
    margin-top: 1.5em;
    margin-inline-start: 0;
  }
  .page.healthcare > navigatable > .content .button {
    max-width: 29vw;
    font-size: 6vw;
    height: 1.75em;
  }
  .page.healthcare > navigatable > .content .button > .icon {
    margin-inline-start: 0.75em;
  }
  .page.healthcare > navigatable > .content > .clinic .flexible > :first-child,
  .page.healthcare
    > navigatable
    > .content
    > :not(.medical-centre)
    > .flexible
    > :first-child {
    margin-bottom: 0;
  }
  .page.healthcare
    > navigatable
    > .content
    > .medical-call-centre
    > .flexible:nth-child(2)
    > icontext {
    margin-top: 2em;
    margin-bottom: 0.25em;
  }
  .page.healthcare
    > navigatable
    > .content
    > .medical-call-centre
    > .contact
    > icontext:nth-of-type(3)
    > .flexible
    > div {
    margin-bottom: 0;
  }
  .page.healthcare navigatable > .content {
    margin-bottom: 2.5em;
  }
}

.main > .scrollable > .page .flexible.pack {
  align-items: flex-start;
  justify-content: flex-start;
}
