.page.media {
  --bg: var(--absolute-white);
  font-size: 2.25em;
}

.page.media .title {
  font-size: 3.5vmax;
}

.page.media navigatable>.content>.navigator>a>.hover-text {
  margin-top: 0.1em;
  font-size: 1.5vmax;
}

.trainingCards decoratedtitle {
  padding-top: 0.5em !important;
  margin-bottom: 0 !important;
}

.trainingCards .textArea {
  padding-top: 0 !important;
}

.page.media .subtitle .title {
  font-size: 2.5vmax;
}

.page.media>navigatable>.content>[icon] {
  margin-bottom: 10vh;
  padding-inline-end: 0;
}

@media only screen and (max-width: 600px) {
  .page.media {
    font-size: 6vw;
  }

  .page.media decoratedtitle {
    margin-top: 2.5em;
    margin-bottom: 1.5em;
  }

  /* .page.media navigatable > .content > .navigator + div {
    margin-top: 4em;
  } */
  .page.media navigatable>.content>.navigator {
    padding: 0 0.3em;
    box-sizing: border-box;
  }

  .page.media navigatable>.content>.navigator>a {
    margin: 0;
    padding: 0.5em;
  }

  .page.media navigatable>.content>.navigator>a>.hover-text {
    font-size: 0.8em;
  }

  .page.media .title {
    font-size: 12.5vw;
  }

  .page.media imgdisplay {
    margin-inline-start: -1em;
    width: calc(100% + 1em);
  }

  .trainingCardList {
    justify-content: center;
  }
}

.bigGallery {
  max-height: 300px !important;
}

.description {
  font-size: 50%;
}

/* .training #teken .swiper-slide:nth-child(2) img {
  object-position: top;
}

.training .swiper-slide:nth-child(3) img {
  object-position: top;
} */

.trainingCardList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 15px;
}

@media only screen and (min-width: 600px) {
  .page.training navigatable>.content>.navigator>a {
    aspect-ratio: 1 / 1;
    padding: 0;
    height: 7vh;
    width: unset;
    margin-bottom: 2.5vh !important;
    background-color: #414042;
  }

  .training .content .navigator a:hover>.hoverText {
    margin-top: 0.15em;
    font-size: 2vmin;
  }

  .training .mainChat svg {
    aspect-ratio: 1 / 1;
    width: unset;
    height: 7vh;
  }
}