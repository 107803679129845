.hospitalWebBanner .container {
  width: 100%;
}

.hospitalWebBanner .container a:before {
  content: "";
  background: -webkit-linear-gradient(top,
      transparent,
      rgba(0, 0, 0, 0.9) 400%);
  position: absolute;
  width: 97%;
  height: 100%;
  border-radius: 25px;
}

.hospitalWebBanner .container .bannerImg {
  width: 100%;
  height: 290px;
  border-radius: 30px;
  object-fit: cover;
}

.hospitalWebBanner .container .bannerDiv {
  position: absolute;
  right: 0;
  margin-right: 10%;
  bottom: 0;
  z-index: 0;
  width: 100%;
}

.hospitalWebBanner .container .bannerDiv .bannerDivTitle {
  font-size: 54px;
  font-weight: 700;
  color: white;
}

.hospitalWebBanner .container .bannerDiv .bannerDivDetail {
  font-size: 22px;
  font-weight: 400;
  color: white;
  text-align: right !important;
}

.hospitalWebBanner .container .bannerDiv .bannerDivDetailButton {
  color: #01a2e6;
  font-size: 20px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .hospitalWebBanner .container .bannerImg {
    height: 200px;
  }

  .hospitalWebBanner .container .bannerDiv .bannerDivDetailButton {
    padding: 2px 8px;
    margin-bottom: 12px;
  }

  .hospitalWebBanner .container .bannerDiv .bannerDivDetail {
    line-height: 1.3;
    margin-bottom: 5px;
  }

  .hospitalWebBanner .container .bannerDiv .bannerDivTitle {
    font-size: 45px;
    margin-bottom: 0;
  }

  .hospitalWebBanner .container {
    padding: 0;
  }
}

.bannerDivDetailDiv {
  display: flex;
  justify-content: right;
}

.bannerDivDetailDiv>* {
  margin-left: 1%;
}