.webDoctorList .doctorsList .doctor_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webDoctorList .doctorsList .doctor_box .box_img {
  max-width: 73%;
  height: unset;
  margin-bottom: 5px;
}

.webDoctorList .doctorsList .doctor_box .box_img img {
  width: 100%;
  border-radius: 50%;
}

.webDoctorList .doctorsList .doctor_box p {
  margin-bottom: 5px;
  color: #8f9bb3;
}

.webDoctorList .doctorsList .doctor_box h6 {
  text-align: center;
  margin-bottom: 5px;
  color: #222b45;
}

.webDoctorList .doctorsList .doctor_box .start_con {
  display: flex;
  align-items: center;
}

.webDoctorList .doctorsList .doctor_box span {
  margin: 0 5px 0 5px;
  font-size: 15px;
  margin-right: 5px;
  font-weight: 400;
  color: #222b45;
}

.webDoctorList .doctorsList .doctor_box svg {
  width: 20px;
  height: 20px;
}
