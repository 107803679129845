.swiperDiv .swiper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.swiperDiv .swiper {
  width: 100%;
  height: auto;
  float: right;
}

.swiperDiv .bigGallery {
  float: none;
  width: 100%;
  height: auto;
}

.swiperDiv .swiper .swiper-slide {
  height: auto;
}

.swiperDiv .mediumGallery {
  margin-left: 50px;
}
