.vandor_details_banner_div {
  position: relative;
}

.vandor_details_banner_div .vandor_details_banner {
  text-align: right !important;
}

.vandor_details_banner_div .vandor_details_banner_img {
  background-image: url("../../../../../assets/images/vandorDetails/bannerBg.jpg");
  border-radius: 10px;
  height: 200px;
  background-position: 50%;
  background-size: cover;
}

.vandor_details_banner_div .vandor_details_banner_img:before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.9) 400%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
}

.vandor_details_banner_div
  .vandor_details_banner_img
  .vandor_details_banner_content
  .banner_content_gift {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid white;
}

.vandor_details_banner_div
  .vandor_details_banner_img
  .vandor_details_banner_content {
  display: flex;
  justify-content: space-between;
}

.vandor_details_banner_div
  .vandor_details_banner_img
  .vandor_details_banner_content {
  padding: 120px 15px 0px 15px;
}

.vandor_details_banner_div
  .vandor_details_banner_img
  .vandor_details_banner_content
  .banner_content_div {
  display: grid;
  margin-right: 15px;
  margin-top: 5px;
  width: 50%;
}

.vandor_details_banner_div
  .vandor_details_banner_img
  .vandor_details_banner_content
  .banner_content_div
  span {
  font-size: 18px;
  font-weight: 500;
  color: white;
  text-shadow: 0px 0px 8px #000;
}

.vandor_details_banner_div
  .vandor_details_banner_img
  .vandor_details_banner_content
  .banner_content_btn {
  background-color: #01a2e6;
  color: white;
  font-size: 16px;
  font-weight: 500;
  height: 35px;
  border-radius: 15px;
  width: 35%;
  margin-top: 15px;
  margin-right: 20%;
}
