.webBlogList {
  padding-top: 2vh;
}

.webPage .image_des_1 {
  position: absolute;
  bottom: 1.5vw;
  right: 1.5vw;
  color: white;
  margin: 0;
  font-size: 25px;
  padding-left: 20%;
  direction: rtl;
  font-size: calc(12px + 0.7vw);
}

.webBlogList img:before {
  content: "";
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(0, 0, 0, 0.9) 400%
  );
  position: absolute;
  width: 100%;
  height: 100%;
}
