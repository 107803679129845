.page.services {
  --bg: var(--absolute-white);
  font-size: 2.5vmin;
}

.page.services .title {
  font-size: 4vmax;
}

decoratedtitle {
  margin-top: 20px;
}

.page.services .navigator .barber-shop>img {
  transform: scale(1.25);
}

/*.page.services navigatable>.content>:not(.navigator){padding-top:4em;margin-bottom:4em}*/

.page.services .barber-shop>.flexible .img img {
  max-width: 6em;
  margin-bottom: 1em;
  height: 80px;
}

.page.services .tickets .img {
  justify-content: start;
  min-width: 60vw;
}

.page.services .tickets .img>* {
  margin: 0 2em;
  width: unset;
  height: 10.5em;
}

.page.services .tickets .img .images>img {
  height: auto;
  width: 100%;
  object-fit: cover;
}

.page.services .religion {
  display: flex;
  flex-direction: column;
}

.page.services .religion .details {
  margin: 0 auto;
}

.page.services .religion .details>icontext {
  margin-bottom: 2em;
}

.page.services .religion .details>icontext>.flexible>* {
  margin-bottom: 0.5em;
}

.page.services .religion .details>icontext> :last-child {
  margin-inline-start: 2em;
}

.page.services .content .barber-shop .queue>.flexible>div> :nth-child(3) {
  display: inline-block;
}

.page.services icontext {
  margin-top: 0;
}

.page.services .religion icontext.location {
  margin-top: 2em;
}

.page.services .religion video {
  margin-top: 4em;
  width: 45%;
  height: auto;
  object-fit: cover;
  align-self: center;
  margin-inline-end: 8vmax;
}

@media only screen and (max-width: 600px) {
  .page.services {
    font-size: 4.8vw;
  }

  .page.services .header .title {
    font-size: 12.5vw;
  }

  .page.services .title {
    font-size: 9vw;
  }

  .page.services .content>div>.flexible {
    flex-direction: column;
  }

  .page.services .content>div>.flexible .flexible.col {
    margin-bottom: 2em;
  }

  .page.services .content>.barber-shop,
  .page.services .content>.religion {
    --max-icon-width: 12vw;
  }

  .page.services .content>.barber-shop>.flexible .flexible.col {
    margin-bottom: 1em;
  }

  .page.services .flexible .queue {
    margin-top: 2em;
  }

  .page.services navigatable>.content> :not(.navigator) {
    margin-bottom: 0;
  }

  .page.services .content .tickets {
    order: -1;
  }

  .page.services .content .tickets .img {
    display: contents;
  }

  .page.services .content .tickets .img> :first-child {
    order: -1;
    margin-top: 0;
  }

  .page.services .content .tickets .img>*,
  .page.services video {
    width: calc(100% + var(--margin-start) * 2);
    /* margin-bottom: 8em; */
    margin-inline-start: calc(var(--margin-start) * -1);
    margin-inline-end: 0;
  }

  /*.services decoratedtitle{padding-top:1.5em;margin-bottom:1.5em} */

  .page.services .content .tickets decoratedtitle {
    order: -1;
  }

  .page.services .content .tickets .flexible>.flexible:first-child {
    display: contents;
    line-height: 1.6em;
  }

  .page.services .content .tickets .flexible>.flexible:first-child>div {
    margin-bottom: 1.6em;
  }

  .page.services .content .barber-shop .queue>.flexible>div>* {
    display: contents;
  }

  .page.services .content .barber-shop .queue>.flexible>div>br {
    display: none;
  }

  .page.services .barber-shop>.flexible .img img {
    height: 13vh;
  }

  .page.services .content>.religion>.flexible .flexible.details {
    margin: 0;
    align-items: flex-start;
  }

  .page.services .religion icontext.location {
    margin-top: 1.5em;
  }

  .page.services .content>.religion>.flexible .flexible.col:first-child {
    margin-bottom: 0.5em;
  }

  .page.services .content>.religion>.flexible .flexible.details>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .page.services .barber-shop>.flexible .img:first-child {
    margin-inline-end: 2em;
  }

  .page.services .content .barber-shop>.flexible .flexible.img {
    margin-bottom: 0;
  }

  .page.services .barber-shop>.flexible .img .flex-end {
    margin-top: 0;
  }

  .page.services .barber-shop>.flexible>.flexible.col>div {
    margin-bottom: 1.5em;
  }

  .page.services .religion .details>icontext> :last-child {
    margin-inline-start: 1em;
  }

  .page.services .religion video {
    height: auto;
    align-self: center;
    width: 100%;
    margin-inline-start: 1em;
  }
}

.page.services navigatable>.content>.textArea> :not(.navigator) {
  padding-top: 3em;
  /* margin-bottom: 4em; */
}

.page.services .barber-shop openinghours>div {
  display: flex;
  justify-content: space-between;
}

.page.services .barber-shop openinghours>div div:last-child {
  margin-right: 5px;
}

.main .page .content .textArea .barber-shop .flexible {
  display: unset;
}

.main .page .content .textArea .barber-shop .flexible .x4 .first-enter {
  margin-top: 1em;
}

.main .page .content .textArea .barber-shop .flexible .x4 .location {
  margin-top: 1em;
}

.main .page .content .textArea .barber-shop .flexible .timeDiv icontext {
  margin-top: 1em;
}

.main .page .content .textArea .barber-shop .flexible .round-cap {
  margin-top: 1em;
}

.main .page .content .textArea .barber-shop .flexible .regular-cap {
  margin-top: 1em;
}

.main .page .content .textArea .tickets .flexible .flexible imgdisplay {
  width: 100%;
  margin-bottom: 0;
}

.row {
  height: auto !important;
}

.main .page .content .textArea .tickets .flexible .flexible video {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-inline-start: 0;
}

.main .page .content .textArea .tickets .flexible .flexible .locationText {
  margin-top: 1em;
}

.main .page .content .textArea .tickets .flexible .flexible icontext {
  margin-top: 1em;
}

/*.main .page .content .textArea .religion .flexible {
  display: unset;
}*/

.services icontext>.icon {
  width: 3vw;
}

@media only screen and (max-width: 600px) {
  .page.services .religion video {
    margin-inline-end: 2vmax;
  }
}

.religion .details .religiesTitles {
  align-items: flex-start;
}