/* Create the Default Paragraphs */
.service_details_pages.rtl * {
  text-align: right !important;
}
.service_details_pages {
  height: 100%;
  max-width: 428px;
  width: 100%;
}

/* Banner Section */
.service_details_pages .banner {
  position: relative;
}
.service_details_pages .banner_content_img {
  width: 100%;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.service_details_pages .banner img {
  position: absolute;
}

.service_details_pages .banner::after {
  width: unset !important;
  height: unset !important;
  content: unset !important;
  position: unset !important;
  top: unset !important;
  left: unset !important;
  background: unset !important;
}

/* .service_details_pages .banner::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #0000004f;
} */

.service_details_pages .banner_content {
  position: relative;
  z-index: 1;
  padding: 0px 0px 0px 0px;
  height: 280px;
}

.service_details_pages .banner_content h1 {
  color: white;
  font-weight: 600;
  font-size: 25px;
  font: medium;
  width: 100%;
  padding-right: 10px;
  position: absolute;
  bottom: 5%;
  right: 3%;
}

.service_details_pages .banner_content h6 {
  color: var(--White-color);
  font-size: 15px;
  font-weight: 400;
  font: medium;
  padding-right: 10px;
}

.service_details_pages .service_details_container {
  padding: 20px 0 10px;
}

.service_details_pages .service_details_container .service_paragraph {
  padding: 0 15px;
  width: 100%;
}

.service_details_pages
  .service_details_container
  .service_paragraph
  .service_details_heading {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.service_details_pages
  .service_details_container
  .service_paragraph
  .service_paragraph_price {
  color: #01a2e6;
  font-size: 17px;
  font-weight: 400;
  /* width: -webkit-fill-available; */
}

.service_details_pages
  .service_details_container
  .service_paragraph
  .service_paragraph_title {
  font-size: 17px;
  font-weight: 400;
  width: -webkit-fill-available;
}

.service_details_pages
  .service_details_container
  .service_paragraph
  .service_paragraph_p {
  margin-top: 5px;
  color: #222b45;
  font-weight: 200;
  font-size: 15px;
  margin-bottom: 20px;
}

.service_details_pages .service_details_container .service_paragraph .d-flex {
  display: flex;
}

.service_details_pages.rtl .box_btn * {
  text-align: center !important;
}

/* Button */
.service_details_pages .box_btn {
  padding: 15px 0;
  box-shadow: 0px -2px 5px 0px #00000042;
  background: white;
  text-align: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  margin-top: 15px;
  z-index: 9;
}

.service_details_pages .box_btn .go_btn {
  max-width: 65%;
  width: 100%;
  margin: 0 auto;
  color: white;
  background: #01a2e6;
  border-radius: 10px;
  display: inline-block;
  padding: 8px 0;
  font-size: 17px;
  font-weight: 700;
  height: 40px;
}

.service_details_pages .doctors_sec .container .doctors_area .doctor_heading {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
