.hospitalStayPage .hospitalStayPageList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hospitalStayPage .hospitalStayPageList .hospitalStaySquare {
  height: 168.5px;
}

.hospitalStayPage
  .hospitalStayPageList
  .hospitalStaySquare
  .hospitalStaySquareImg {
  width: 90%;
  border-radius: 10px;
  max-width: 386px;
  max-height: 138px;
  margin: 0 20px;
  object-fit: cover;
}

.hospitalStayPage
  .hospitalStayPageList
  .hospitalStaySquare
  .hospitalStaySquareHeader {
  position: relative;
  bottom: 25%;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  color: #01a2e6;
  background-color: white;
  width: fit-content;
  width: -moz-fit-content;
  padding: 0 8px;
  border-radius: 7px;
  right: 10px;
  display: inline-block;
  margin: 0 21px;
}
